import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
//AWS Amplify config
import Amplify, { API } from 'aws-amplify';
import awsmobile from './aws-exports';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
Amplify.configure(awsmobile);
API.configure(awsmobile);
if (environment.production) {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .catch(function (err) { return console.error(err); });
