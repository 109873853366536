import * as _ from 'lodash';
export var AWS_CLASS = {
    RESPONSES: {
        STATUSES: {
            SUCCESS: 'success',
            ERROR: 'error',
        },
        CODES: {
            UNHANDLED: -1,
            //Status
            STATUS_200: 200,
            STATUS_204: 204,
            STATUS_500: 500,
            //Data
            DATA_400: 400,
            DATA_404: 404,
            //Authentication
            AUTH_401: 401,
            AUTH_403: 403,
        }
    },
};
var AWSClass = /** @class */ (function () {
    function AWSClass(modalService) {
        this.modalService = modalService;
    }
    AWSClass.prototype.handleResponse = function (apiResponse, origin) {
        console.log((origin ? (origin + ' response ->') : ''), apiResponse);
        //Check for error
        if (apiResponse.status === AWS_CLASS.RESPONSES.STATUSES.SUCCESS) {
            //Success
            return Promise.resolve(apiResponse);
        }
        else {
            //Error
            return Promise.reject(this.getErrorResponse(apiResponse, origin));
        }
    };
    AWSClass.prototype.getSuccessResponse = function (body, code, message) {
        //Init response
        var response = {
            status: AWS_CLASS.RESPONSES.STATUSES.SUCCESS,
            code: code ? code : AWS_CLASS.RESPONSES.CODES.STATUS_200,
            body: body,
            message: (!message && code === AWS_CLASS.RESPONSES.CODES.STATUS_200) ? 'OK' : message,
        };
        return response;
    };
    AWSClass.prototype.getErrorResponse = function (error, origin, code, showModal) {
        //Init response
        var errorResponse = {
            status: AWS_CLASS.RESPONSES.STATUSES.ERROR,
            code: code ? code : _.get(error, 'code', AWS_CLASS.RESPONSES.CODES.UNHANDLED),
            body: _.get(error, 'body', error),
            message: _.get(error, 'body.description', _.get(error, 'message', 'Unhandled error happened')),
        };
        //Print response
        console.log('Error in ' + (origin ? origin : '(not provided)'), error);
        console.log('errorResponse -> ', errorResponse);
        if (showModal) {
            this.modalService.presentErrorAlert({
                title: 'Error',
                content: errorResponse.message,
            });
        }
        return errorResponse;
    };
    AWSClass.prototype.getCouldNotConnectError = function () {
        return 'Could not make a connection to the backend right now.';
    };
    return AWSClass;
}());
export { AWSClass };
