import { NzModalService } from 'ng-zorro-antd';
import { HardLoaderComponent } from './components/hard-loader/hard-loader.component';
export var MODAL_ALERT_TYPE_INFO = 'info';
export var MODAL_ALERT_TYPE_SUCCESS = 'success';
export var MODAL_ALERT_TYPE_ERROR = 'error';
export var MODAL_ALERT_TYPE_WARNING = 'warning';
export var MODAL_ALERT_TYPE_CONFIRM = 'confirm';
var ModalService = /** @class */ (function () {
    function ModalService(nzModalService) {
        this.nzModalService = nzModalService;
        this.hardLoaderModal = null;
        this.alertModal = null;
    }
    ModalService.prototype.initOptions = function (options) {
        if (!options) {
            options = {};
        }
        return options;
    };
    ModalService.prototype.presentHardLoader = function (options) {
        options = this.initOptions(options);
        this.hardLoaderModal = this.nzModalService.create({
            nzTitle: null,
            nzContent: HardLoaderComponent,
            nzComponentParams: { content: options.content ? options.content : 'Loading...' },
            nzMask: true,
            nzClosable: false,
            nzMaskClosable: false,
            nzFooter: null,
        });
    };
    ModalService.prototype.updateHardLoaderContent = function (content) {
        if (this.hardLoaderModal) {
            var hardLoaderContent = this.hardLoaderModal.getContentComponent();
            if (hardLoaderContent) {
                hardLoaderContent.content = content;
            }
        }
    };
    ModalService.prototype.dismissHardLoader = function () {
        if (this.hardLoaderModal) {
            this.hardLoaderModal.close();
            this.hardLoaderModal = null;
        }
    };
    ModalService.prototype.presentErrorAlert = function (options) {
        options = this.initOptions(options);
        options.type = MODAL_ALERT_TYPE_ERROR;
        this.presentAlert(options);
    };
    ModalService.prototype.presentWarningAlert = function (options) {
        options = this.initOptions(options);
        options.type = MODAL_ALERT_TYPE_WARNING;
        this.presentAlert(options);
    };
    ModalService.prototype.presentConfirmAlert = function (options) {
        options = this.initOptions(options);
        options.type = MODAL_ALERT_TYPE_CONFIRM;
        this.presentAlert(options);
    };
    ModalService.prototype.presentAlert = function (options) {
        options = this.initOptions(options);
        var modalOptions = {
            nzTitle: options.title ? options.title : null,
            nzContent: options.content ? options.content : null,
            nzClosable: false,
            nzMaskClosable: false,
            nzOkText: options.okButtonText ? options.okButtonText : 'Ok',
        };
        switch (options.type) {
            case MODAL_ALERT_TYPE_SUCCESS:
                //Success
                modalOptions.nzTitle = modalOptions.nzTitle === null ? 'Success' : modalOptions.nzTitle;
                this.alertModal = this.nzModalService.success(modalOptions);
                break;
            case MODAL_ALERT_TYPE_ERROR:
                //Error
                modalOptions.nzTitle = modalOptions.nzTitle === null ? 'Error' : modalOptions.nzTitle;
                this.alertModal = this.nzModalService.error(modalOptions);
                break;
            case MODAL_ALERT_TYPE_WARNING:
                //Warning
                modalOptions.nzTitle = modalOptions.nzTitle === null ? 'Warning' : modalOptions.nzTitle;
                this.alertModal = this.nzModalService.warning(modalOptions);
                break;
            case MODAL_ALERT_TYPE_CONFIRM:
                //Confirm
                modalOptions.nzTitle = modalOptions.nzTitle === null ? 'Confirm' : modalOptions.nzTitle;
                modalOptions.nzOnOk = options.okButtonFunction ? options.okButtonFunction : function () { };
                modalOptions.nzOnCancel = options.cancelButtonFunction ? options.cancelButtonFunction : function () { };
                this.alertModal = this.nzModalService.confirm(modalOptions);
                break;
            default:
                //Info
                modalOptions.nzTitle = modalOptions.nzTitle === null ? 'Info' : modalOptions.nzTitle;
                this.alertModal = this.nzModalService.info(modalOptions);
                break;
        }
    };
    ModalService.prototype.dismissAlert = function () {
        if (this.alertModal) {
            this.alertModal.close();
            this.alertModal = null;
        }
    };
    return ModalService;
}());
export { ModalService };
