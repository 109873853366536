import { AWSDataHomeContentService } from '../../aws/data/home-content/aws-data-home-content';
import { AWS_CLASS } from '../../aws/aws-class';
import * as i0 from "@angular/core";
import * as i1 from "../../aws/data/home-content/aws-data-home-content";
var DataHomeContentService = /** @class */ (function () {
    function DataHomeContentService(AWSDataHomeContentService) {
        this.AWSDataHomeContentService = AWSDataHomeContentService;
    }
    DataHomeContentService.prototype.get = function () {
        return this.AWSDataHomeContentService.get()
            .then(function (response) {
            console.log('response', response);
            if (response.status === AWS_CLASS.RESPONSES.STATUSES.SUCCESS) {
                //return Promise.resolve(JSON.parse(response.body));
                return Promise.resolve(response.body);
            }
        })
            .catch(function (error) {
            console.log('error', error);
            return Promise.reject(error);
        });
    };
    DataHomeContentService.prototype.set = function (homeContent) {
        var setOpts = {
            body: { 'file_body': homeContent, }
        };
        return this.AWSDataHomeContentService.set(setOpts)
            .then(function () {
            return Promise.resolve(true);
        })
            .catch(function (error) {
            console.log('error', error);
            return Promise.reject(error);
        });
    };
    DataHomeContentService.ngInjectableDef = i0.defineInjectable({ factory: function DataHomeContentService_Factory() { return new DataHomeContentService(i0.inject(i1.AWSDataHomeContentService)); }, token: DataHomeContentService, providedIn: "root" });
    return DataHomeContentService;
}());
export { DataHomeContentService };
