import { OnInit } from '@angular/core';
import { DateTimeService } from '../../../services/date-time/date-time.service';
var LayoutFooterComponent = /** @class */ (function () {
    function LayoutFooterComponent(dateTimeService) {
        this.dateTimeService = dateTimeService;
        this.year = '';
    }
    LayoutFooterComponent.prototype.ngOnInit = function () {
        //Set year
        this.year = this.dateTimeService.today('YYYY');
    };
    return LayoutFooterComponent;
}());
export { LayoutFooterComponent };
