import * as tslib_1 from "tslib";
import { environment } from '../../../../../environments/environment';
import * as _ from 'lodash';
import { AWSClass, AWS_CLASS } from '../../aws-class';
import { AWSAPIService } from '../../helpers/api/aws-api';
import { GlobalVarsService } from '../../../global-vars/global-vars.service';
import { AWSAuthService } from '../../helpers/auth/aws-auth';
import { ModalService } from 'src/app/services/modal/modal.service';
export var AWS_DATA_EMPLOYEES = {
    ACTIONS: {
        AUTHENTICATE_WEB: 'authenticateWeb',
    },
    ERRORS: {}
};
var AWSDataEmployeesService = /** @class */ (function (_super) {
    tslib_1.__extends(AWSDataEmployeesService, _super);
    function AWSDataEmployeesService(AWSAPIService, AWSAuthService, globalVarsService, modalService) {
        var _this = 
        //Populate parent services
        _super.call(this, modalService) || this;
        _this.AWSAPIService = AWSAPIService;
        _this.AWSAuthService = AWSAuthService;
        _this.globalVarsService = globalVarsService;
        _this.modalService = modalService;
        return _this;
    }
    AWSDataEmployeesService.prototype.initOptions = function () {
        var opts = {
            path: environment.api.paths.employees,
            body: {}
        };
        return opts;
    };
    AWSDataEmployeesService.prototype.authenticate = function (authenticateData) {
        var _this = this;
        //Init options
        var options = this.initOptions();
        //Set anonymous request
        options.anonymous = true;
        //Set body
        var optionsBody = {
            username: authenticateData.username,
            password: authenticateData.password,
            action: AWS_DATA_EMPLOYEES.ACTIONS.AUTHENTICATE_WEB,
        };
        options.body = optionsBody;
        //Hit API with POST method
        return this.AWSAPIService.post(options)
            .then(function (apiResponse) {
            //Check for error
            if (apiResponse.status === AWS_CLASS.RESPONSES.STATUSES.SUCCESS) {
                //Get employee info from payload
                var employee = apiResponse.body;
                if (employee) {
                    //Sign in user in AWS user pool
                    return _this.AWSAuthService.signIn(authenticateData.username, authenticateData.password)
                        .then(function (signInResponse) {
                        return Promise.resolve(_this.getSuccessResponse(signInResponse));
                    })
                        .catch(function (signInError) {
                        return Promise.reject(_this.getErrorResponse(signInError, 'signIn'));
                    });
                }
                else {
                    //Error - No employee given
                    return Promise.reject(_this.getErrorResponse(null, 'signIn'));
                }
            }
            else {
                //Error
                return Promise.reject(_this.getErrorResponse(apiResponse, 'authenticate'));
            }
        })
            .catch(function (apiError) {
            return Promise.reject(_this.getErrorResponse(apiError, 'authenticate', null, true));
        });
    };
    AWSDataEmployeesService.prototype.getErrorResponse = function (error, origin, code, showModal) {
        //Init code
        code = code ? code : parseInt(_.get(error, 'code', 0));
        //Check for error
        var errorBody = {
            code: code,
            description: _.get(error, 'message', null)
        };
        //Set message if error is handled
        switch (code) {
            case AWS_CLASS.RESPONSES.CODES.AUTH_401:
                //Unauthorized
                errorBody.description = 'Username/Password combination is invalid. Please check and try again.';
                break;
        }
        //Set error body
        if (!error) {
            error = {};
        }
        error['body'] = errorBody;
        return _super.prototype.getErrorResponse.call(this, error, origin, code, showModal);
    };
    return AWSDataEmployeesService;
}(AWSClass));
export { AWSDataEmployeesService };
