import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

import { GlobalVarsService, EVENTS, IEvent } from './services/global-vars/global-vars.service';
import { ModalService } from './services/modal/modal.service';
import { MediaQueryService } from './services/media-query/media-query.service';
import { AWSAuthService, IEmployee } from './services/aws/helpers/auth/aws-auth';
import { APP_ROUTES } from './app-routes';
import { IPort } from './components/layout/layout-header/layout-header.component';
import { DataPortsService } from './services/data/ports/ports.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  routerEventsStream: Subscription;

  mobileLayout: boolean = false;
  mobileLayoutStream: Subscription;

  siderCollapsed: boolean = true;
  siderCollapsedStream: Subscription;

  user: IEmployee = null;
  selectedPort: IPort = null;
  events: Subscription;

  constructor(
    public globalVarsService: GlobalVarsService,
    public modalService: ModalService,
    public mediaQueryService: MediaQueryService,
    public AWSAuthService: AWSAuthService,
    public router: Router,
    public dataPortsService: DataPortsService,
  ) { }

  ngOnInit() {
    //Get small screen value and subscribe to changes
    this.mobileLayout = this.mediaQueryService.getMobileLayout();
    this.mobileLayoutStream = this.mediaQueryService.mobileLayoutChange.subscribe((mobileLayout) => {
      this.mobileLayout = mobileLayout;
    });

    //Get sider collapsed value and subscribe to changes
    this.siderCollapsed = this.globalVarsService.getSiderCollapsed();
    this.siderCollapsedStream = this.globalVarsService.siderCollapsedStream.subscribe((siderCollapsed) => {
      this.siderCollapsed = siderCollapsed;
    });

    //Subscribe to router events
    this.routerEventsStream = this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        //Update current URL
        this.globalVarsService.setCurrentURL(event.urlAfterRedirects.replace('/', ''));
      }
    });

    //Subscribe to events
    this.events = this.globalVarsService.eventStream
      .subscribe((event: IEvent) => {
        //Process event
        switch (event.name) {
          case EVENTS.LOAD_DATA:
            this.loadData();
            break;
          case EVENTS.LOGOUT:
            this.confirmLogOut();
            break;
          case EVENTS.SELECTED_PORT:
            this.setSelectedPort(event.data ? event.data : null);
            break;
          case EVENTS.PORTS:
            this.setPorts(event.data ? event.data : null);
            break;
        }
      });

    //Load data
    this.loadData();
  }

  ngOnDestroy() {
    //Unsubscribe from mobileLayout changes
    if (this.mobileLayoutStream) {
      this.mobileLayoutStream.unsubscribe();
    }

    //Unsubscribe from sider collapsed changes
    if (this.siderCollapsedStream) {
      this.siderCollapsedStream.unsubscribe();
    }

    //Unsubscribe from router events
    if (this.routerEventsStream) {
      this.routerEventsStream.unsubscribe();
    }

    //Unsubscribe from events
    if (this.events) {
      this.events.unsubscribe();
    }
  }

  loadData(): void {
    //User
    this.AWSAuthService.getCurrentEmployee()
      .then((employee) => {
        //Set user
        this.user = employee;
        this.globalVarsService.setUser(employee);

        if (this.user) {
          //Ports
          this.dataPortsService.get()
            .then(async (ports) => {
              this.setPorts(ports);

              //If there's no selected location then select the first one by default
              if (!this.selectedPort) {
                let selectedPort: IPort = null;
                const lastPort: IPort = await this.dataPortsService.getLastPort();
                if (lastPort) {
                  selectedPort = lastPort;
                } else {
                  selectedPort = ports[0];
                }
                this.setSelectedPort(selectedPort);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
  }

  setSelectedPort(port: IPort) {
    this.selectedPort = port;
    this.globalVarsService.setSelectedPort(port);
  }

  setPorts(ports: IPort[]) {
    this.globalVarsService.setPorts(ports);
  }

  clearData() {
    this.globalVarsService.setUser(null);
    this.setSelectedPort(null);
  }

  confirmLogOut() {
    this.modalService.presentConfirmAlert({
      title: 'Confirm log out',
      content: 'Are you sure you want to log out?',
      okButtonText: 'Yes',
      okButtonFunction: () => { this.performLogOut() },
    });
  }

  performLogOut() {
    this.modalService.presentHardLoader({ content: 'Logging you out...' });

    this.AWSAuthService.signOut()
      .then(() => {
        this.clearData();
        this.modalService.dismissHardLoader();
        this.router.navigate([APP_ROUTES.login]);
      })
      .catch(() => {
        this.modalService.dismissHardLoader();
      });
  }
}
