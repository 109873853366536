/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hard-loader.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/ng-zorro-antd/spin/ng-zorro-antd-spin.ngfactory";
import * as i3 from "ng-zorro-antd/spin";
import * as i4 from "./hard-loader.component";
var styles_HardLoaderComponent = [i0.styles];
var RenderType_HardLoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HardLoaderComponent, data: {} });
export { RenderType_HardLoaderComponent as RenderType_HardLoaderComponent };
export function View_HardLoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "text-align-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "nz-spin", [["nzSimple", ""]], [[2, "ant-spin-nested-loading", null]], null, null, i2.View_NzSpinComponent_0, i2.RenderType_NzSpinComponent)), i1.ɵdid(2, 770048, null, 0, i3.NzSpinComponent, [i1.ChangeDetectorRef], { nzSimple: [0, "nzSimple"] }, null), (_l()(), i1.ɵted(3, null, [" ", "\n"]))], function (_ck, _v) { var currVal_1 = ""; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !i1.ɵnov(_v, 2).nzSimple; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.content; _ck(_v, 3, 0, currVal_2); }); }
export function View_HardLoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hard-loader", [], null, null, null, View_HardLoaderComponent_0, RenderType_HardLoaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.HardLoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HardLoaderComponentNgFactory = i1.ɵccf("app-hard-loader", i4.HardLoaderComponent, View_HardLoaderComponent_Host_0, {}, {}, []);
export { HardLoaderComponentNgFactory as HardLoaderComponentNgFactory };
