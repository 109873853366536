import { OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { GlobalVarsService, EVENTS } from '../../../services/global-vars/global-vars.service';
import { DataPortsService } from 'src/app/services/data/ports/ports.service';
import { ModalService } from 'src/app/services/modal/modal.service';
var LayoutMenuComponent = /** @class */ (function () {
    function LayoutMenuComponent(globalVarsService, formBuilder, dataPortsService, modalService) {
        this.globalVarsService = globalVarsService;
        this.formBuilder = formBuilder;
        this.dataPortsService = dataPortsService;
        this.modalService = modalService;
        this.isSideMenu = false;
        this.ports = [];
        this.selectedPort = null;
        this.user = null;
        this.currentURL = '';
        this.unsavedData = false;
    }
    LayoutMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        //Set form
        this.setForm();
        //Get current URL value and subscribe to changes
        this.currentURL = this.globalVarsService.getCurrentURL();
        this.currentURLSubscription = this.globalVarsService.currentURLStream
            .subscribe(function (currentURL) {
            _this.currentURL = currentURL;
        });
        //Get ports value and subscribe to changes
        this.ports = this.globalVarsService.getPorts();
        this.portsSubscription = this.globalVarsService.portsStream
            .subscribe(function (ports) {
            _this.ports = ports;
        });
        //Get port value and subscribe to changes
        this.selectedPort = this.globalVarsService.getSelectedPort();
        this.selectedPortSubscription = this.globalVarsService.selectedPortStream
            .subscribe(function (selectedPort) {
            _this.setPort(selectedPort);
        });
        //Get user value and subscribe to changes
        this.user = this.globalVarsService.getUser();
        this.userSubscription = this.globalVarsService.userStream
            .subscribe(function (user) {
            _this.user = user;
        });
        //Get unsaved cahnges value and subscribe to changes
        this.unsavedData = this.globalVarsService.getUnsavedData();
        this.unsavedDataSubscription = this.globalVarsService.unsavedDataStream
            .subscribe(function (unsavedData) {
            _this.unsavedData = unsavedData;
        });
    };
    LayoutMenuComponent.prototype.ngOnDestroy = function () {
        //Unsubscribe from current URL value changes
        if (this.currentURLSubscription) {
            this.currentURLSubscription.unsubscribe();
        }
        //Unsubscribe from form port code changes
        if (this.formPortCodeSubscription) {
            this.formPortCodeSubscription.unsubscribe();
        }
        //Unsubscribe from ports changes
        if (this.portsSubscription) {
            this.portsSubscription.unsubscribe();
        }
        //Unsubscribe from selected port changes
        if (this.selectedPortSubscription) {
            this.selectedPortSubscription.unsubscribe();
        }
        //Unsubscribe from user changes
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        //Unsubscribe from unsaved data changes
        if (this.unsavedDataSubscription) {
            this.unsavedDataSubscription.unsubscribe();
        }
    };
    LayoutMenuComponent.prototype.setForm = function () {
        var _this = this;
        this.menuForm = this.formBuilder.group({
            portCode: [],
        });
        //Subscribe to form port code changes
        this.formPortCodeSubscription = this.menuForm.get('portCode').valueChanges
            .subscribe(function (value) {
            _this.setPort(_.find(_this.ports, { code: value }));
        });
    };
    LayoutMenuComponent.prototype.setPort = function (newPort, skipCheckUnsavedData) {
        var _this = this;
        if (!skipCheckUnsavedData && this.unsavedData) {
            //Alert user that unsaved data is about to be lost
            this.modalService.presentConfirmAlert({
                title: 'Confirm changing port',
                content: 'There are data that haven\'t been saved. Are you sure you wanna change port?',
                okButtonText: 'Yes',
                okButtonFunction: function () {
                    _this.setPort(newPort, true);
                },
                cancelButtonFunction: function () {
                    _this.menuForm.get('portCode').setValue(_this.selectedPort.code, { emitEvent: false });
                },
            });
        }
        else {
            //Proceed to change port
            if (newPort && newPort !== this.selectedPort) {
                //Set unsaved data to false
                this.unsavedData = false;
                this.globalVarsService.setUnsavedData(false);
                //Set port value
                this.selectedPort = newPort;
                this.menuForm.get('portCode').setValue(this.selectedPort.code, { emitEvent: false });
                //Trigger event changing port
                var selectedPortEvent = {
                    name: EVENTS.SELECTED_PORT,
                    data: this.selectedPort
                };
                this.globalVarsService.setEvent(selectedPortEvent);
            }
        }
    };
    LayoutMenuComponent.prototype.performLogOut = function () {
        //Trigger logout event
        var logoutEvent = {
            name: EVENTS.LOGOUT,
        };
        this.globalVarsService.setEvent(logoutEvent);
        //Close side menu
        if (this.isSideMenu) {
            var sideMenuEvent = {
                name: EVENTS.TOGGLE_SIDER,
            };
            this.globalVarsService.setEvent(sideMenuEvent);
        }
    };
    return LayoutMenuComponent;
}());
export { LayoutMenuComponent };
