import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { GlobalVarsService, EVENTS, IEvent } from '../../services/global-vars/global-vars.service';
import { ModalService } from '../../services/modal/modal.service';
import { AWSAuthService } from './../../services/aws/helpers/auth/aws-auth';
import { APP_ROUTES } from 'src/app/app-routes';
import { AWSDataEmployeesService, IAWSDataAuthenticateData } from 'src/app/services/aws/data/employees/aws-data-employees';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  skipCheckSession: boolean = false;
  returnUrl: string = null;

  constructor(
    public formBuilder: FormBuilder,
    public globalVarsService: GlobalVarsService,
    public modalService: ModalService,
    public AWSAuthService: AWSAuthService,
    public AWSDataEmployeesService: AWSDataEmployeesService,
    public router: Router,
    public route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    //Set form
    this.loginForm = this.formBuilder.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });

    //Set skipCheckSession
    this.skipCheckSession = this.route.snapshot.queryParams['skipCheckSession'] === 'true';

    //Set returnUrl
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];

    //Check if we have a valid session
    //This is required here in order to avoid an error, see https://blog.angular-university.io/angular-debugging/
    setTimeout(() => {
      this.checkSession();
    })
  }

  checkSession(): void {
    if (!this.skipCheckSession) {
      this.modalService.presentHardLoader({ content: 'Checking your session...' });

      this.AWSAuthService.isValidSession()
        .then((isValidSession) => {
          this.modalService.dismissHardLoader();

          if (isValidSession) {
            this.goToTransactions();
          }
        })
        .catch(() => {
          this.modalService.dismissHardLoader();
        });
    }
  }

  performLogin(): void {
    const authenticateOpts: IAWSDataAuthenticateData = {
      username: this.loginForm.get('username').value,
      password: this.loginForm.get('password').value
    };

    this.modalService.presentHardLoader({ content: 'Logging you in...' });
    this.AWSDataEmployeesService.authenticate(authenticateOpts)
      .then(() => {
        //If success then populate user
        this.AWSAuthService.getCurrentEmployee()
          .then(() => {
            //Dismiss loader
            this.modalService.dismissHardLoader();

            //Trigger event to load data
            const loadDataEvent: IEvent = {
              name: EVENTS.LOAD_DATA,
            };
            this.globalVarsService.setEvent(loadDataEvent);

            if (this.returnUrl) {
              this.router.navigate([this.returnUrl]);
            } else {
              this.goToTransactions();
            }
          })
          .catch(() => {
            //Dismiss loader
            this.modalService.dismissHardLoader();
          });
      })
      .catch(() => {
        //Dismiss loader
        this.modalService.dismissHardLoader();
      });
  }

  goToTransactions(): void {
    this.router.navigate([APP_ROUTES.home]);
  }
}