import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { APP_ROUTES } from './app-routes';

const routes: Routes = [
  { path: '', redirectTo: APP_ROUTES.login, pathMatch: 'full' },
  { path: APP_ROUTES.login, component: LoginComponent },

  { path: APP_ROUTES.home, component: HomeComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }