//NgZorro config
import { en_US } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
registerLocaleData(en);
import { BREAKPOINTS } from '@angular/flex-layout';
import { FLEX_LAYOUT_CUSTOM_BREAKPOINTS } from './services/media-query/media-query.service';
export var BreakPointsProvider = {
    provide: BREAKPOINTS,
    useValue: FLEX_LAYOUT_CUSTOM_BREAKPOINTS
};
var ɵ0 = en_US;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
