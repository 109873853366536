import * as tslib_1 from "tslib";
import { environment } from '../../../../../environments/environment';
import * as _ from 'lodash';
import { AWSClass } from '../../aws-class';
import { ModalService } from '../../../modal/modal.service';
import { AWSAPIService } from '../../helpers/api/aws-api';
var AWSDataHomeContentService = /** @class */ (function (_super) {
    tslib_1.__extends(AWSDataHomeContentService, _super);
    function AWSDataHomeContentService(AWSAPIService, modalService) {
        var _this = 
        //Populate alert service
        _super.call(this, modalService) || this;
        _this.AWSAPIService = AWSAPIService;
        _this.modalService = modalService;
        return _this;
    }
    AWSDataHomeContentService.prototype.initOptions = function (options) {
        var opts = {
            path: _.get(options, 'path', environment.api.paths.homeContent),
            body: _.get(options, 'body', {}),
        };
        return opts;
    };
    AWSDataHomeContentService.prototype.get = function (options) {
        var _this = this;
        return this.AWSAPIService.get(this.initOptions(options))
            .then(function (apiResponse) {
            return _this.handleResponse(apiResponse, 'dataHomeContent get');
        })
            .catch(function (apiError) {
            return Promise.reject(_this.getErrorResponse(apiError, 'dataHomeContent get'));
        });
    };
    AWSDataHomeContentService.prototype.set = function (options) {
        var _this = this;
        return this.AWSAPIService.post(this.initOptions(options))
            .then(function (apiResponse) {
            return _this.handleResponse(apiResponse, 'dataHomeContent set');
        })
            .catch(function (apiError) {
            return Promise.reject(_this.getErrorResponse(apiError, 'dataHomeContent set'));
        });
    };
    return AWSDataHomeContentService;
}(AWSClass));
export { AWSDataHomeContentService };
