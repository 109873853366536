import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { APP_ROUTES } from './app-routes';
var routes = [
    { path: '', redirectTo: APP_ROUTES.login, pathMatch: 'full' },
    { path: APP_ROUTES.login, component: LoginComponent },
    { path: APP_ROUTES.home, component: HomeComponent, canActivate: [AuthGuard] },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
