import { EventEmitter } from '@angular/core';
import { DataPortsService } from '../data/ports/ports.service';
//Events
export var EVENTS = {
    LOAD_DATA: 'event::load_data',
    LOGOUT: 'event::logout',
    TOGGLE_SIDER: 'event::toggle_sider',
    SELECTED_PORT: 'event::selected_port',
    PORTS: 'event::ports',
};
var GlobalVarsService = /** @class */ (function () {
    function GlobalVarsService(dataPortsService) {
        this.dataPortsService = dataPortsService;
        //Used to detect when the sider is collapsed
        this.siderCollapsed = true;
        this.siderCollapsedStream = new EventEmitter();
        //Used to detect the current URL
        this.currentURL = '';
        this.currentURLStream = new EventEmitter();
        //Used to trigger events
        this.event = null;
        this.eventStream = new EventEmitter();
        //Used to keep the current user
        this.user = null;
        this.userStream = new EventEmitter();
        //Used to keep all available ports
        this.ports = [];
        this.portsStream = new EventEmitter();
        //Used to keep the selected port
        this.selectedPort = null;
        this.selectedPortStream = new EventEmitter();
        //Used to detect when there are unsaved data
        this.unsavedData = false;
        this.unsavedDataStream = new EventEmitter();
    }
    GlobalVarsService.prototype.getSiderCollapsed = function () {
        return this.siderCollapsed;
    };
    GlobalVarsService.prototype.setSiderCollapsed = function (value) {
        this.siderCollapsed = value;
        this.siderCollapsedStream.emit(this.siderCollapsed);
    };
    GlobalVarsService.prototype.getCurrentURL = function () {
        return this.currentURL;
    };
    GlobalVarsService.prototype.setCurrentURL = function (value) {
        this.currentURL = value;
        this.currentURLStream.emit(this.currentURL);
    };
    GlobalVarsService.prototype.getEvent = function () {
        return this.event;
    };
    GlobalVarsService.prototype.setEvent = function (value) {
        this.event = value;
        this.eventStream.emit(this.event);
    };
    GlobalVarsService.prototype.getUser = function () {
        return this.user;
    };
    GlobalVarsService.prototype.setUser = function (value) {
        this.user = value;
        this.userStream.emit(this.user);
    };
    GlobalVarsService.prototype.getPorts = function () {
        return this.ports;
    };
    GlobalVarsService.prototype.setPorts = function (value) {
        this.ports = value;
        this.portsStream.emit(this.ports);
    };
    GlobalVarsService.prototype.getSelectedPort = function () {
        return this.selectedPort;
    };
    GlobalVarsService.prototype.setSelectedPort = function (value) {
        this.selectedPort = value;
        this.selectedPortStream.emit(this.selectedPort);
        if (value !== null) {
            this.dataPortsService.setLastPort(this.selectedPort);
        }
        else {
            this.dataPortsService.deleteLastPort();
        }
    };
    GlobalVarsService.prototype.getUnsavedData = function () {
        return this.unsavedData;
    };
    GlobalVarsService.prototype.setUnsavedData = function (value) {
        this.unsavedData = value;
        this.unsavedDataStream.emit(this.unsavedData);
    };
    return GlobalVarsService;
}());
export { GlobalVarsService };
