import { OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { GlobalVarsService, EVENTS } from 'src/app/services/global-vars/global-vars.service';
import { MediaQueryService } from 'src/app/services/media-query/media-query.service';
import { DataPortsService } from 'src/app/services/data/ports/ports.service';
var LayoutHeaderComponent = /** @class */ (function () {
    function LayoutHeaderComponent(router, globalVarsService, mediaQueryService, dataPortsService) {
        this.router = router;
        this.globalVarsService = globalVarsService;
        this.mediaQueryService = mediaQueryService;
        this.dataPortsService = dataPortsService;
        this.navigating = true;
        this.siderCollapsed = true;
        this.mobileLayout = true;
        this.user = null;
    }
    LayoutHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        //Get sider collapsed value
        this.siderCollapsed = this.globalVarsService.getSiderCollapsed();
        //Get mobileLayout value and subscribe to changes
        this.mobileLayout = this.mediaQueryService.getMobileLayout();
        this.mobileLayoutStream = this.mediaQueryService.mobileLayoutChange
            .subscribe(function (mobileLayout) {
            _this.mobileLayout = mobileLayout;
        });
        //Get user value and subscribe to changes
        this.user = this.globalVarsService.getUser();
        this.userSubscription = this.globalVarsService.userStream
            .subscribe(function (user) {
            _this.user = user;
        });
        //Subscribe to events
        this.events = this.globalVarsService.eventStream
            .subscribe(function (event) {
            //Process event
            switch (event) {
                case EVENTS.TOGGLE_SIDER:
                    _this.toggleSider();
                    break;
            }
        });
        //Subscribe to router events
        this.routerEventsSubscription = this.router.events
            .subscribe(function (event) {
            if (event instanceof NavigationStart) {
                _this.navigating = true;
            }
            else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                _this.navigating = false;
            }
        });
    };
    LayoutHeaderComponent.prototype.ngOnDestroy = function () {
        //Unsubscribe from mobileLayout changes
        if (this.mobileLayoutStream) {
            this.mobileLayoutStream.unsubscribe();
        }
        //Unsubscribe from user changes
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        //Unsubscribe from events
        if (this.events) {
            this.events.unsubscribe();
        }
        //Unsubscribe from router events
        if (this.routerEventsSubscription) {
            this.routerEventsSubscription.unsubscribe();
        }
    };
    LayoutHeaderComponent.prototype.toggleSider = function () {
        this.siderCollapsed = !this.siderCollapsed;
        this.globalVarsService.setSiderCollapsed(this.siderCollapsed);
    };
    return LayoutHeaderComponent;
}());
export { LayoutHeaderComponent };
