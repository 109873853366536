import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';

export const TIMEZONES = {
  BNE: 'Australia/Brisbane',
  PER: 'Australia/Perth',
  SYD: 'Australia/Sydney',
  MEL: 'Australia/Melbourne',
};

@Injectable()
export class DateTimeService {
  timezone: string = TIMEZONES.BNE;

  constructor() { }

  initFormat(format?: string): string {
    return !format ? 'YYYY-MM-DD' : format;
  }

  today(format?: string): string {
    return this.format(null, format);
  }

  format(date: any, format?: string): string {
    format = this.initFormat(format);
    const momentObj = date ? moment(date) : moment();
    return (this.timezone && !date) ? momentObj.tz(this.timezone).format(format) : momentObj.format(format);
  }

  isDateGreaterThanToday(currentDate: Date): boolean {
    return moment(new Date()).diff(currentDate, 'days') < 0;
  }
}