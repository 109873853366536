import { NgModule } from '@angular/core';

//AWS Amplify module and service
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';

import { AWSAPIService } from './aws-api';
import { AWSAuthServiceModule } from '../auth/aws-auth.module';

@NgModule({
  imports: [
    AmplifyAngularModule,
    AWSAuthServiceModule,
  ],
  providers: [
    AmplifyService,
    AWSAPIService,
  ]
})
export class AWSAPIServiceModule { }