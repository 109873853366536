import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//App config
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//NgZorro config
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
registerLocaleData(en);

//Flexlayout
import { FlexLayoutModule } from "@angular/flex-layout";
import { BREAKPOINTS } from '@angular/flex-layout';
import { FLEX_LAYOUT_CUSTOM_BREAKPOINTS } from './services/media-query/media-query.service';
export const BreakPointsProvider = {
  provide: BREAKPOINTS,
  useValue: FLEX_LAYOUT_CUSTOM_BREAKPOINTS
};

//Quill config
import { QuillModule } from 'ngx-quill';

//Guards
import { AuthGuard } from './guards/auth.guard';

//CDK modules
import { DragDropModule } from '@angular/cdk/drag-drop';

//Layout components
import { LayoutFooterComponent } from './components/layout/layout-footer/layout-footer.component';
import { LayoutHeaderComponent } from './components/layout/layout-header/layout-header.component';
import { LayoutMenuComponent } from './components/layout/layout-menu/layout-menu.component';

//Services
import { GlobalVarsServiceModule } from './services/global-vars/global-vars.service.module';
import { DateTimeServiceModule } from './services/date-time/date-time.service.module';
import { ModalServiceModule } from './services/modal/modal.service.module';
import { MediaQueryServiceModule } from './services/media-query/media-query.service.module';
import { AWSAuthServiceModule } from './services/aws/helpers/auth/aws-auth.module';
import { AWSDataHomeContentServiceModule } from './services/aws/data/home-content/aws-data-home-content.module';
import { AWSDataEmployeesServiceModule } from './services/aws/data/employees/aws-data-employees.module';

//Helpers components
import { LoaderComponent } from './components/helpers/loader/loader.component';
import { InfiniteProgressComponent } from './components/helpers/infinite-progress/infinite-progress.component';

//Pages components
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { DataPortsServiceModule } from './services/data/ports/ports.module';
import { AWSDataPortsServiceServiceModule } from './services/aws/data/ports/aws-data-ports.module';

@NgModule({
  declarations: [
    AppComponent,

    //Layout
    LayoutFooterComponent,
    LayoutHeaderComponent,
    LayoutMenuComponent,

    //Helpers components
    LoaderComponent,
    InfiniteProgressComponent,

    //Pages components
    HomeComponent,
    LoginComponent,
  ],

  imports: [
    //Angular
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,

    //NgZorro
    NgZorroAntdModule,

    //Flexlayout
    FlexLayoutModule,

    //Quill
    QuillModule,

    //CDK
    DragDropModule,

    //Services
    GlobalVarsServiceModule,
    DateTimeServiceModule,
    ModalServiceModule,
    MediaQueryServiceModule,
    AWSAuthServiceModule,
    AWSDataHomeContentServiceModule,
    AWSDataPortsServiceServiceModule,
    AWSDataEmployeesServiceModule,
    DataPortsServiceModule,
  ],

  providers: [
    //NgZorro
    { provide: NZ_I18N, useValue: en_US },
    
    //Flexlayout
    BreakPointsProvider,

    //Guards
    AuthGuard,
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }