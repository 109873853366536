import { Component, OnInit } from '@angular/core';

import { DateTimeService } from '../../../services/date-time/date-time.service';

@Component({
  selector: 'app-layout-footer',
  templateUrl: './layout-footer.component.html',
  styleUrls: ['./layout-footer.component.scss']
})
export class LayoutFooterComponent implements OnInit {
  year: string = '';

  constructor(
    public dateTimeService: DateTimeService,
  ) { }

  ngOnInit() {
    //Set year
    this.year = this.dateTimeService.today('YYYY');
  }
}