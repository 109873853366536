import { OnDestroy, EventEmitter } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
//FlexLayout Breakpoints
export var FLEX_LAYOUT_CUSTOM_BREAKPOINT_XS = 'xs';
export var FLEX_LAYOUT_CUSTOM_BREAKPOINT_SM = 'sm';
export var FLEX_LAYOUT_CUSTOM_BREAKPOINT_MD = 'md';
export var FLEX_LAYOUT_CUSTOM_BREAKPOINT_LG = 'lg';
export var FLEX_LAYOUT_CUSTOM_BREAKPOINT_XL = 'xl';
export var FLEX_LAYOUT_CUSTOM_BREAKPOINT_XXL = 'xxl';
export var FLEX_LAYOUT_CUSTOM_BREAKPOINTS = [
    { alias: FLEX_LAYOUT_CUSTOM_BREAKPOINT_XS, mediaQuery: '(min-width: 0px) and (max-width: 575px)' },
    { alias: FLEX_LAYOUT_CUSTOM_BREAKPOINT_SM, mediaQuery: '(min-width: 576px) and (max-width: 767px)' },
    { alias: FLEX_LAYOUT_CUSTOM_BREAKPOINT_MD, mediaQuery: '(min-width: 768px) and (max-width: 991px)' },
    { alias: FLEX_LAYOUT_CUSTOM_BREAKPOINT_LG, mediaQuery: '(min-width: 992px) and (max-width: 1199px)' },
    { alias: FLEX_LAYOUT_CUSTOM_BREAKPOINT_XL, mediaQuery: '(min-width: 1200px) and (max-width: 1599px)' },
    { alias: FLEX_LAYOUT_CUSTOM_BREAKPOINT_XXL, mediaQuery: '(min-width: 1600px) and (max-width: 5000px)' },
];
export var FLEX_LAYOUT_MOBILE_BREAKPOINTS = [
    FLEX_LAYOUT_CUSTOM_BREAKPOINT_XS,
    FLEX_LAYOUT_CUSTOM_BREAKPOINT_SM,
    FLEX_LAYOUT_CUSTOM_BREAKPOINT_MD,
    FLEX_LAYOUT_CUSTOM_BREAKPOINT_LG,
];
var MediaQueryService = /** @class */ (function () {
    function MediaQueryService(mediaObserver) {
        var _this = this;
        this.mediaObserver = mediaObserver;
        this.mqAlias = '';
        this.mqAliasChange = new EventEmitter();
        this.mobileLayout = false;
        this.mobileLayoutChange = new EventEmitter();
        this.isXsScreen = false;
        this.isXsScreenChange = new EventEmitter();
        //Subscribe to mediaObserverStream changes
        this.mediaObserverSubscription = this.mediaObserver.media$.subscribe(function (change) {
            //Set media query alias
            _this.setMqAlias(change.mqAlias);
            //Set mobileLayout value
            _this.setMobileLayout();
            //Set isXsScreen value
            _this.setIsXsScreen();
        });
    }
    MediaQueryService.prototype.ngOnDestroy = function () {
        //Unsubscribe from mediaObserverStream changes
        if (this.mediaObserverSubscription) {
            this.mediaObserverSubscription.unsubscribe();
        }
    };
    MediaQueryService.prototype.getMqAlias = function () {
        return this.mqAlias;
    };
    MediaQueryService.prototype.setMqAlias = function (value) {
        this.mqAlias = value;
        this.mqAliasChange.emit(this.mqAlias);
    };
    MediaQueryService.prototype.getMobileLayout = function () {
        return this.mobileLayout;
    };
    MediaQueryService.prototype.setMobileLayout = function () {
        this.mobileLayout = FLEX_LAYOUT_MOBILE_BREAKPOINTS.indexOf(this.getMqAlias()) !== -1;
        this.mobileLayoutChange.emit(this.mobileLayout);
    };
    MediaQueryService.prototype.getIsXsScreen = function () {
        return this.isXsScreen;
    };
    MediaQueryService.prototype.setIsXsScreen = function () {
        this.isXsScreen = this.mqAlias === FLEX_LAYOUT_CUSTOM_BREAKPOINT_XS;
        this.isXsScreenChange.emit(this.getIsXsScreen());
    };
    return MediaQueryService;
}());
export { MediaQueryService };
