import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import * as _ from 'lodash';

import { AWSDataPortsService } from '../../aws/data/ports/aws-data-ports';
import { AWS_CLASS } from '../../aws/aws-class';

export interface IDataPort {
  code: string,
  name: string,
  country: string,
  active: boolean,
}

@Injectable({
  providedIn: 'root'
})
export class DataPortsService {
  DB_LAST_PORT_KEY = 'last-port';

  constructor(
    public AWSDataPortsService: AWSDataPortsService,
    public localStorage: LocalStorage,
  ) { }

  get(): Promise<IDataPort[]> {
    return this.AWSDataPortsService.get()
      .then((response) => {
        let ports: IDataPort[] = [];
        if (response.status === AWS_CLASS.RESPONSES.STATUSES.SUCCESS) {
          const responsePorts = response.body;
          if (Array.isArray(responsePorts) && responsePorts.length > 0) {
            for (const responsePort of responsePorts) {
              ports.push(this.mapAPIItem(responsePort));
            }
          }
        }

        return Promise.resolve(ports);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  mapAPIItem(apiItem: any): IDataPort {
    const dataItem: IDataPort = {
      code: _.get(apiItem, 'Code', ''),
      name: _.get(apiItem, 'Name', ''),
      country: _.get(apiItem, 'Country', ''),
      active: (_.get(apiItem, 'Active', false) === '1' ? true : false),
    };

    return dataItem;
  }

  getLastPort(): Promise<IDataPort> {
    return this.localStorage.getItem(this.DB_LAST_PORT_KEY).toPromise();
  }

  setLastPort(port: IDataPort): Promise<Boolean> {
    return this.localStorage.setItem(this.DB_LAST_PORT_KEY, port).toPromise();
  }

  deleteLastPort(): Promise<Boolean>  { 
    return this.localStorage.removeItem(this.DB_LAST_PORT_KEY).toPromise();
  }
}