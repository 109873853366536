import { Router } from '@angular/router';
import { AWSAuthService } from '../services/aws/helpers/auth/aws-auth';
import { APP_ROUTES } from '../app-routes';
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, AWSAuthService) {
        this.router = router;
        this.AWSAuthService = AWSAuthService;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this.AWSAuthService.isValidSession()
            .then(function () {
            return true;
        })
            .catch(function () {
            _this.router.navigate([APP_ROUTES.login], { queryParams: { skipCheckSession: true, returnUrl: state.url } });
            return false;
        });
    };
    return AuthGuard;
}());
export { AuthGuard };
