import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import * as _ from 'lodash';

import { AWSClass, IAWSResponse, IAWSError, AWS_CLASS } from '../../aws-class';
import { AWSAPIService, IAWSAPIRequestOpts } from '../../helpers/api/aws-api';
import { GlobalVarsService } from '../../../global-vars/global-vars.service';
import { AWSAuthService } from '../../helpers/auth/aws-auth';
import { ModalService } from 'src/app/services/modal/modal.service';

export const AWS_DATA_EMPLOYEES = {
  ACTIONS: {
    AUTHENTICATE_WEB: 'authenticateWeb',
  },
  ERRORS: {}
}

export interface IAWSDataEmployeesPostBody {
  username: string,
  password: string,
  action?: string,
}

export interface IAWSDataAuthenticateData {
  username: string,
  password: string,
  action?: string,
}

@Injectable()
export class AWSDataEmployeesService extends AWSClass {
  constructor(
    public AWSAPIService: AWSAPIService,
    public AWSAuthService: AWSAuthService,
    public globalVarsService: GlobalVarsService,
    public modalService: ModalService,
  ) {
    //Populate parent services
    super(modalService);
  }

  initOptions(): IAWSAPIRequestOpts {
    const opts: IAWSAPIRequestOpts = {
      path: environment.api.paths.employees,
      body: {}
    };

    return opts;
  }

  authenticate(authenticateData: IAWSDataAuthenticateData): Promise<IAWSResponse> {
    //Init options
    let options = this.initOptions();

    //Set anonymous request
    options.anonymous = true;

    //Set body
    const optionsBody: IAWSDataEmployeesPostBody = {
      username: authenticateData.username,
      password: authenticateData.password,
      action: AWS_DATA_EMPLOYEES.ACTIONS.AUTHENTICATE_WEB,
    }
    options.body = optionsBody;

    //Hit API with POST method
    return this.AWSAPIService.post(options)
      .then((apiResponse) => {
        //Check for error
        if (apiResponse.status === AWS_CLASS.RESPONSES.STATUSES.SUCCESS) {
          //Get employee info from payload
          const employee = apiResponse.body;
          if (employee) {
            //Sign in user in AWS user pool
            return this.AWSAuthService.signIn(authenticateData.username, authenticateData.password)
              .then((signInResponse) => {
                return Promise.resolve(this.getSuccessResponse(signInResponse));
              })
              .catch((signInError) => {
                return Promise.reject(this.getErrorResponse(signInError, 'signIn'));
              });
          } else {
            //Error - No employee given
            return Promise.reject(this.getErrorResponse(null, 'signIn'));
          }

        } else {
          //Error
          return Promise.reject(this.getErrorResponse(apiResponse, 'authenticate'));
        }
      })
      .catch((apiError) => {
        return Promise.reject(this.getErrorResponse(apiError, 'authenticate', null, true));
      });
  }

  getErrorResponse(error: any, origin?: string, code?: number, showModal?: boolean): IAWSResponse {
    //Init code
    code = code ? code : parseInt(_.get(error, 'code', 0));

    //Check for error
    let errorBody: IAWSError = {
      code: code,
      description: _.get(error, 'message', null)
    };

    //Set message if error is handled
    switch (code) {
      case AWS_CLASS.RESPONSES.CODES.AUTH_401:
        //Unauthorized
        errorBody.description = 'Username/Password combination is invalid. Please check and try again.';
        break;
    }

    //Set error body
    if (!error) {
      error = {};
    }
    error['body'] = errorBody;

    return super.getErrorResponse(error, origin, code, showModal);
  }
}