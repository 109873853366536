import { Component, OnInit, OnDestroy, ViewEncapsulation, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

import { GlobalVarsService, EVENTS, IEvent } from '../../../services/global-vars/global-vars.service';
import { IPort } from '../layout-header/layout-header.component';
import { IEmployee } from 'src/app/services/aws/helpers/auth/aws-auth';
import { DataPortsService } from 'src/app/services/data/ports/ports.service';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-layout-menu',
  templateUrl: './layout-menu.component.html',
  styleUrls: ['./layout-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutMenuComponent implements OnInit, OnDestroy {
  @Input() isSideMenu: boolean = false;

  ports: IPort[] = [];
  portsSubscription: Subscription;

  selectedPort: IPort = null;
  selectedPortSubscription: Subscription;

  user: IEmployee = null;
  userSubscription: Subscription;

  currentURL: string = '';
  currentURLSubscription: Subscription;

  unsavedData: boolean = false;
  unsavedDataSubscription: Subscription;

  menuForm: FormGroup;
  formPortCodeSubscription: Subscription;

  constructor(
    public globalVarsService: GlobalVarsService,
    public formBuilder: FormBuilder,
    public dataPortsService: DataPortsService,
    public modalService: ModalService,
  ) { }

  ngOnInit() {
    //Set form
    this.setForm();

    //Get current URL value and subscribe to changes
    this.currentURL = this.globalVarsService.getCurrentURL();
    this.currentURLSubscription = this.globalVarsService.currentURLStream
      .subscribe((currentURL: string) => {
        this.currentURL = currentURL;
      });

    //Get ports value and subscribe to changes
    this.ports = this.globalVarsService.getPorts();
    this.portsSubscription = this.globalVarsService.portsStream
      .subscribe((ports: IPort[]) => {
        this.ports = ports;
      });

    //Get port value and subscribe to changes
    this.selectedPort = this.globalVarsService.getSelectedPort();
    this.selectedPortSubscription = this.globalVarsService.selectedPortStream
      .subscribe((selectedPort: IPort) => {
        this.setPort(selectedPort);
      });

    //Get user value and subscribe to changes
    this.user = this.globalVarsService.getUser();
    this.userSubscription = this.globalVarsService.userStream
      .subscribe((user: IEmployee) => {
        this.user = user;
      });

    //Get unsaved cahnges value and subscribe to changes
    this.unsavedData = this.globalVarsService.getUnsavedData();
    this.unsavedDataSubscription = this.globalVarsService.unsavedDataStream
      .subscribe((unsavedData: boolean) => {
        this.unsavedData = unsavedData;
      });
  }

  ngOnDestroy() {
    //Unsubscribe from current URL value changes
    if (this.currentURLSubscription) {
      this.currentURLSubscription.unsubscribe();
    }

    //Unsubscribe from form port code changes
    if (this.formPortCodeSubscription) {
      this.formPortCodeSubscription.unsubscribe();
    }

    //Unsubscribe from ports changes
    if (this.portsSubscription) {
      this.portsSubscription.unsubscribe();
    }

    //Unsubscribe from selected port changes
    if (this.selectedPortSubscription) {
      this.selectedPortSubscription.unsubscribe();
    }

    //Unsubscribe from user changes
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    //Unsubscribe from unsaved data changes
    if (this.unsavedDataSubscription) {
      this.unsavedDataSubscription.unsubscribe();
    }
  }

  setForm() {
    this.menuForm = this.formBuilder.group({
      portCode: [],
    });

    //Subscribe to form port code changes
    this.formPortCodeSubscription = this.menuForm.get('portCode').valueChanges
      .subscribe((value) => {
        this.setPort(_.find(this.ports, { code: value }) as IPort);
      });
  }

  setPort(newPort?: IPort, skipCheckUnsavedData?: boolean) {
    if (!skipCheckUnsavedData && this.unsavedData) {
      //Alert user that unsaved data is about to be lost
      this.modalService.presentConfirmAlert({
        title: 'Confirm changing port',
        content: 'There are data that haven\'t been saved. Are you sure you wanna change port?',
        okButtonText: 'Yes',
        okButtonFunction: () => {
          this.setPort(newPort, true);
        },
        cancelButtonFunction: () => { 
          this.menuForm.get('portCode').setValue(this.selectedPort.code, { emitEvent: false });
        },
      });
    } else {
      //Proceed to change port
      if (newPort && newPort !== this.selectedPort) {
        //Set unsaved data to false
        this.unsavedData = false;
        this.globalVarsService.setUnsavedData(false);

        //Set port value
        this.selectedPort = newPort;
        this.menuForm.get('portCode').setValue(this.selectedPort.code, { emitEvent: false });

        //Trigger event changing port
        const selectedPortEvent: IEvent = {
          name: EVENTS.SELECTED_PORT,
          data: this.selectedPort
        };
        this.globalVarsService.setEvent(selectedPortEvent);
      }
    }
  }

  performLogOut() {
    //Trigger logout event
    const logoutEvent: IEvent = {
      name: EVENTS.LOGOUT,
    };
    this.globalVarsService.setEvent(logoutEvent);

    //Close side menu
    if (this.isSideMenu) {
      const sideMenuEvent: IEvent = {
        name: EVENTS.TOGGLE_SIDER,
      };
      this.globalVarsService.setEvent(sideMenuEvent);
    }
  }
}