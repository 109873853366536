/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout-footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ng-zorro-antd/layout/ng-zorro-antd-layout.ngfactory";
import * as i3 from "ng-zorro-antd/layout";
import * as i4 from "./layout-footer.component";
import * as i5 from "../../../services/date-time/date-time.service";
var styles_LayoutFooterComponent = [i0.styles];
var RenderType_LayoutFooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutFooterComponent, data: {} });
export { RenderType_LayoutFooterComponent as RenderType_LayoutFooterComponent };
export function View_LayoutFooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "nz-footer", [["class", "text-align-center"], ["id", "layout-footer"]], null, null, null, i2.View_NzFooterComponent_0, i2.RenderType_NzFooterComponent)), i1.ɵdid(1, 49152, null, 0, i3.NzFooterComponent, [i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵted(2, 0, [" Swissport \u00A9", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.year; _ck(_v, 2, 0, currVal_0); }); }
export function View_LayoutFooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout-footer", [], null, null, null, View_LayoutFooterComponent_0, RenderType_LayoutFooterComponent)), i1.ɵdid(1, 114688, null, 0, i4.LayoutFooterComponent, [i5.DateTimeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutFooterComponentNgFactory = i1.ɵccf("app-layout-footer", i4.LayoutFooterComponent, View_LayoutFooterComponent_Host_0, {}, {}, []);
export { LayoutFooterComponentNgFactory as LayoutFooterComponentNgFactory };
