export const environment = {
  production: true,

  //API constants
  api: {
    name: 'Employee_Companion',
    paths: {
      homeContent: '/home-content/',
      ports: '/ports/',
      employees: '/employees/',
    }
  },

  //Amplify config
  amplify: {
    API: {
      endpoints: [
        {
          name: "Employee_Companion",
          endpoint: "https://0qlqkimf1f.execute-api.ap-southeast-2.amazonaws.com/staging"
        }
      ]
    },
  },
};