import { NgModule } from '@angular/core';

import { AWSDataHomeContentService } from './aws-data-home-content';
import { AWSAPIServiceModule } from '../../helpers/api/aws-api.module';

@NgModule({
  imports: [
    AWSAPIServiceModule,
  ],
  providers: [
    AWSDataHomeContentService,
  ]
})
export class AWSDataHomeContentServiceModule { }