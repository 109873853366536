import { NgModule } from '@angular/core';

import { ModalService } from './modal.service';
import { HardLoaderComponent } from './components/hard-loader/hard-loader.component';

//NGZorro UI
import { NgZorroAntdModule } from 'ng-zorro-antd';

@NgModule({
  declarations: [
    HardLoaderComponent,
  ],
  imports: [
    NgZorroAntdModule,
  ],
  entryComponents: [
    HardLoaderComponent,
  ],
  providers: [
    ModalService,
  ],
})
export class ModalServiceModule { }