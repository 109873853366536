/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng-zorro-antd/core";
import * as i3 from "ng-zorro-antd/grid";
import * as i4 from "@angular/cdk/layout";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "../../../../../node_modules/ng-zorro-antd/spin/ng-zorro-antd-spin.ngfactory";
import * as i7 from "ng-zorro-antd/spin";
import * as i8 from "./loader.component";
var styles_LoaderComponent = [i0.styles];
var RenderType_LoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoaderComponent, data: {} });
export { RenderType_LoaderComponent as RenderType_LoaderComponent };
export function View_LoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["nz-row", ""]], [[8, "hidden", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.NzUpdateHostClassService, i2.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(2, 4931584, null, 0, i3.NzRowDirective, [i1.ElementRef, i1.Renderer2, i2.NzUpdateHostClassService, i4.MediaMatcher, i1.NgZone, i5.Platform], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "text-align-center margin-bottom"], ["nz-col", ""], ["nzXs", "24"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.NzUpdateHostClassService, i2.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(5, 4931584, null, 0, i3.NzColDirective, [i2.NzUpdateHostClassService, i1.ElementRef, [2, i3.NzRowDirective], i1.Renderer2], { nzXs: [0, "nzXs"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "nz-spin", [["nzSimple", ""]], [[2, "ant-spin-nested-loading", null]], null, null, i6.View_NzSpinComponent_0, i6.RenderType_NzSpinComponent)), i1.ɵdid(7, 770048, null, 0, i7.NzSpinComponent, [i1.ChangeDetectorRef], { nzSimple: [0, "nzSimple"] }, null), (_l()(), i1.ɵted(-1, null, [" Loading... "]))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_1 = "24"; _ck(_v, 5, 0, currVal_1); var currVal_3 = ""; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hidden; _ck(_v, 0, 0, currVal_0); var currVal_2 = !i1.ɵnov(_v, 7).nzSimple; _ck(_v, 6, 0, currVal_2); }); }
export function View_LoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, View_LoaderComponent_0, RenderType_LoaderComponent)), i1.ɵdid(1, 114688, null, 0, i8.LoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoaderComponentNgFactory = i1.ɵccf("app-loader", i8.LoaderComponent, View_LoaderComponent_Host_0, { hidden: "hidden" }, {}, []);
export { LoaderComponentNgFactory as LoaderComponentNgFactory };
