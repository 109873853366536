import * as tslib_1 from "tslib";
import { environment } from '../../../../../environments/environment';
import * as _ from 'lodash';
import { AWSClass } from '../../aws-class';
import { ModalService } from '../../../modal/modal.service';
import { AWSAPIService } from '../../helpers/api/aws-api';
var AWSDataPortsService = /** @class */ (function (_super) {
    tslib_1.__extends(AWSDataPortsService, _super);
    function AWSDataPortsService(AWSAPIService, modalService) {
        var _this = 
        //Populate alert service
        _super.call(this, modalService) || this;
        _this.AWSAPIService = AWSAPIService;
        _this.modalService = modalService;
        return _this;
    }
    AWSDataPortsService.prototype.initOptions = function (options) {
        var opts = {
            path: _.get(options, 'path', environment.api.paths.ports),
            body: _.get(options, 'body', {}),
        };
        return opts;
    };
    AWSDataPortsService.prototype.get = function (options) {
        var _this = this;
        return this.AWSAPIService.get(this.initOptions(options))
            .then(function (apiResponse) {
            return _this.handleResponse(apiResponse, 'ports get');
        })
            .catch(function (apiError) {
            return Promise.reject(_this.getErrorResponse(apiError, 'ports get'));
        });
    };
    return AWSDataPortsService;
}(AWSClass));
export { AWSDataPortsService };
