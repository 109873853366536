import { Injectable, OnDestroy, EventEmitter } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

//FlexLayout Breakpoints
export const FLEX_LAYOUT_CUSTOM_BREAKPOINT_XS = 'xs';
export const FLEX_LAYOUT_CUSTOM_BREAKPOINT_SM = 'sm';
export const FLEX_LAYOUT_CUSTOM_BREAKPOINT_MD = 'md';
export const FLEX_LAYOUT_CUSTOM_BREAKPOINT_LG = 'lg';
export const FLEX_LAYOUT_CUSTOM_BREAKPOINT_XL = 'xl';
export const FLEX_LAYOUT_CUSTOM_BREAKPOINT_XXL = 'xxl';
export const FLEX_LAYOUT_CUSTOM_BREAKPOINTS = [
  { alias: FLEX_LAYOUT_CUSTOM_BREAKPOINT_XS, mediaQuery: '(min-width: 0px) and (max-width: 575px)' },
  { alias: FLEX_LAYOUT_CUSTOM_BREAKPOINT_SM, mediaQuery: '(min-width: 576px) and (max-width: 767px)' },
  { alias: FLEX_LAYOUT_CUSTOM_BREAKPOINT_MD, mediaQuery: '(min-width: 768px) and (max-width: 991px)' },
  { alias: FLEX_LAYOUT_CUSTOM_BREAKPOINT_LG, mediaQuery: '(min-width: 992px) and (max-width: 1199px)' },
  { alias: FLEX_LAYOUT_CUSTOM_BREAKPOINT_XL, mediaQuery: '(min-width: 1200px) and (max-width: 1599px)' },
  { alias: FLEX_LAYOUT_CUSTOM_BREAKPOINT_XXL, mediaQuery: '(min-width: 1600px) and (max-width: 5000px)' },
];
export const FLEX_LAYOUT_MOBILE_BREAKPOINTS = [
  FLEX_LAYOUT_CUSTOM_BREAKPOINT_XS,
  FLEX_LAYOUT_CUSTOM_BREAKPOINT_SM,
  FLEX_LAYOUT_CUSTOM_BREAKPOINT_MD,
  FLEX_LAYOUT_CUSTOM_BREAKPOINT_LG,
];

@Injectable()
export class MediaQueryService implements OnDestroy {
  mediaObserverSubscription: Subscription;

  mqAlias: string = '';
  mqAliasChange: EventEmitter<string> = new EventEmitter();

  mobileLayout: boolean = false;
  mobileLayoutChange: EventEmitter<boolean> = new EventEmitter();

  isXsScreen: boolean = false;
  isXsScreenChange: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public mediaObserver: MediaObserver,
  ) {
    //Subscribe to mediaObserverStream changes
    this.mediaObserverSubscription = this.mediaObserver.media$.subscribe((change: MediaChange) => {
      //Set media query alias
      this.setMqAlias(change.mqAlias);

      //Set mobileLayout value
      this.setMobileLayout();

      //Set isXsScreen value
      this.setIsXsScreen();
    });
  }

  ngOnDestroy() {
    //Unsubscribe from mediaObserverStream changes
    if (this.mediaObserverSubscription) {
      this.mediaObserverSubscription.unsubscribe();
    }
  }

  getMqAlias(): string {
    return this.mqAlias;
  }

  private setMqAlias(value: string): void {
    this.mqAlias = value;
    this.mqAliasChange.emit(this.mqAlias);
  }

  getMobileLayout(): boolean {
    return this.mobileLayout;
  }

  private setMobileLayout(): void {
    this.mobileLayout = FLEX_LAYOUT_MOBILE_BREAKPOINTS.indexOf(this.getMqAlias()) !== -1;
    this.mobileLayoutChange.emit(this.mobileLayout);
  }

  getIsXsScreen(): boolean {
    return this.isXsScreen;
  }

  private setIsXsScreen(): void {
    this.isXsScreen = this.mqAlias === FLEX_LAYOUT_CUSTOM_BREAKPOINT_XS;
    this.isXsScreenChange.emit(this.getIsXsScreen());
  }
}
