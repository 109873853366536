import * as moment from 'moment-timezone';
export var TIMEZONES = {
    BNE: 'Australia/Brisbane',
    PER: 'Australia/Perth',
    SYD: 'Australia/Sydney',
    MEL: 'Australia/Melbourne',
};
var DateTimeService = /** @class */ (function () {
    function DateTimeService() {
        this.timezone = TIMEZONES.BNE;
    }
    DateTimeService.prototype.initFormat = function (format) {
        return !format ? 'YYYY-MM-DD' : format;
    };
    DateTimeService.prototype.today = function (format) {
        return this.format(null, format);
    };
    DateTimeService.prototype.format = function (date, format) {
        format = this.initFormat(format);
        var momentObj = date ? moment(date) : moment();
        return (this.timezone && !date) ? momentObj.tz(this.timezone).format(format) : momentObj.format(format);
    };
    DateTimeService.prototype.isDateGreaterThanToday = function (currentDate) {
        return moment(new Date()).diff(currentDate, 'days') < 0;
    };
    return DateTimeService;
}());
export { DateTimeService };
