<nz-header id="layout-header" [ngClass]="{'small-screen': mobileLayout === true}">
  <div id="layout-header-container">
    <div id="layout-header-logo"></div>

    <ng-container *ngIf="user !== null">
      <app-layout-menu [hidden]="mobileLayout !== false"></app-layout-menu>

      <ng-container *ngIf="mobileLayout === true">
        <ul nz-menu id="layout-header-user-menu" nzTheme="dark" nzMode="horizontal">
          <li nz-submenu class='no-padding' (click)="toggleSider()">
            <span title><i nz-icon [type]="siderCollapsed === false ? 'menu-unfold' : 'menu-fold'" class="no-margin"></i></span>
          </li>
        </ul>
      </ng-container>
    </ng-container>
  </div>
</nz-header>

<app-infinite-progress *ngIf="navigating === true"></app-infinite-progress>