import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { GlobalVarsService, EVENTS } from '../../services/global-vars/global-vars.service';
import { ModalService } from '../../services/modal/modal.service';
import { AWSAuthService } from './../../services/aws/helpers/auth/aws-auth';
import { APP_ROUTES } from 'src/app/app-routes';
import { AWSDataEmployeesService } from 'src/app/services/aws/data/employees/aws-data-employees';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(formBuilder, globalVarsService, modalService, AWSAuthService, AWSDataEmployeesService, router, route) {
        this.formBuilder = formBuilder;
        this.globalVarsService = globalVarsService;
        this.modalService = modalService;
        this.AWSAuthService = AWSAuthService;
        this.AWSDataEmployeesService = AWSDataEmployeesService;
        this.router = router;
        this.route = route;
        this.skipCheckSession = false;
        this.returnUrl = null;
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        //Set form
        this.loginForm = this.formBuilder.group({
            username: [null, [Validators.required]],
            password: [null, [Validators.required]],
        });
        //Set skipCheckSession
        this.skipCheckSession = this.route.snapshot.queryParams['skipCheckSession'] === 'true';
        //Set returnUrl
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
        //Check if we have a valid session
        //This is required here in order to avoid an error, see https://blog.angular-university.io/angular-debugging/
        setTimeout(function () {
            _this.checkSession();
        });
    };
    LoginComponent.prototype.checkSession = function () {
        var _this = this;
        if (!this.skipCheckSession) {
            this.modalService.presentHardLoader({ content: 'Checking your session...' });
            this.AWSAuthService.isValidSession()
                .then(function (isValidSession) {
                _this.modalService.dismissHardLoader();
                if (isValidSession) {
                    _this.goToTransactions();
                }
            })
                .catch(function () {
                _this.modalService.dismissHardLoader();
            });
        }
    };
    LoginComponent.prototype.performLogin = function () {
        var _this = this;
        var authenticateOpts = {
            username: this.loginForm.get('username').value,
            password: this.loginForm.get('password').value
        };
        this.modalService.presentHardLoader({ content: 'Logging you in...' });
        this.AWSDataEmployeesService.authenticate(authenticateOpts)
            .then(function () {
            //If success then populate user
            _this.AWSAuthService.getCurrentEmployee()
                .then(function () {
                //Dismiss loader
                _this.modalService.dismissHardLoader();
                //Trigger event to load data
                var loadDataEvent = {
                    name: EVENTS.LOAD_DATA,
                };
                _this.globalVarsService.setEvent(loadDataEvent);
                if (_this.returnUrl) {
                    _this.router.navigate([_this.returnUrl]);
                }
                else {
                    _this.goToTransactions();
                }
            })
                .catch(function () {
                //Dismiss loader
                _this.modalService.dismissHardLoader();
            });
        })
            .catch(function () {
            //Dismiss loader
            _this.modalService.dismissHardLoader();
        });
    };
    LoginComponent.prototype.goToTransactions = function () {
        this.router.navigate([APP_ROUTES.home]);
    };
    return LoginComponent;
}());
export { LoginComponent };
