//Import environment
import { environment } from './environments/environment';

const awsmobile = {
  "aws_project_region": "ap-southeast-2",
  "aws_cognito_identity_pool_id": "ap-southeast-2:7b87fc20-e191-496f-aedb-3f1707e731ab",
  "aws_cognito_region": "ap-southeast-2",
  "aws_user_pools_id": "ap-southeast-2_VbLXMMzZX",
  "aws_user_pools_web_client_id": "389lfae223l6ntqip30q3r9p43",
  "oauth": {},

  //Add API endpoints
  API: environment.amplify.API,

  //BEGIN OF AUTH
  /*Auth: {
    oauth: {
      // Domain name
      domain: 'myaeronet.auth.ap-southeast-2.amazoncognito.com',

      // Authorized scopes
      scope: ['email', 'openid'],

      // Callback URL
      redirectSignIn: 'https://aerocare.com.au/sso/saml2/idp/SSOService.php?spentityid=urn:amazon:cognito:sp:4r37v5eb3um083i5620mti11l4',

      // Sign out URL
      redirectSignOut: 'https://aerocare.com.au/sso/saml2/idp/initSLO.php?RelayState=/sso/',

      // 'code' for Authorization code grant, 
      // 'token' for Implicit grant
      responseType: 'token',

      // optional, for Cognito hosted ui specified options
      options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        AdvancedSecurityDataCollectionFlag: true
      }
    }
  }*/
  //END OF AUTH
};


export default awsmobile;
