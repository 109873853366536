import { NgModule } from '@angular/core';

import { AWSDataPortsService } from './aws-data-ports';
import { AWSAPIServiceModule } from '../../helpers/api/aws-api.module';

@NgModule({
  imports: [
    AWSAPIServiceModule,
  ],
  providers: [
    AWSDataPortsService,
  ]
})
export class AWSDataPortsServiceServiceModule { }