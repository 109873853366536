import * as tslib_1 from "tslib";
import { environment } from './../../../../../environments/environment';
import * as _ from 'lodash';
import { AWSClass, AWS_CLASS } from '../../aws-class';
import { AmplifyService } from 'aws-amplify-angular';
import { AWSAuthService, AWS_AUTH } from '../auth/aws-auth';
import { ModalService } from 'src/app/services/modal/modal.service';
var AWSAPIService = /** @class */ (function (_super) {
    tslib_1.__extends(AWSAPIService, _super);
    function AWSAPIService(amplifyService, AWSAuthService, modalService) {
        var _this = 
        //Call extending class constructor
        _super.call(this, modalService) || this;
        _this.amplifyService = amplifyService;
        _this.AWSAuthService = AWSAuthService;
        _this.modalService = modalService;
        //Populate apiObj
        _this.apiObj = amplifyService.api();
        return _this;
    }
    AWSAPIService.prototype.initOptions = function (options) {
        var _this = this;
        //API name
        options.apiName = _.get(options, 'apiName', environment.api.name);
        //Headers
        options.headers = _.get(options, 'headers', {});
        //Content type
        if (!options.headers['Content-Type']) {
            options.headers['Content-Type'] = 'application/json';
        }
        //Check if request is anonymous
        if (_.get(options, 'anonymous', false) === true) {
            return Promise.resolve(options);
        }
        else {
            //Get JWT to authenticate request
            return this.AWSAuthService.getJWT()
                .then(function (jwt) {
                //Add Authorization header
                if (!options.headers['Authorization']) {
                    options.headers['Authorization'] = jwt;
                }
                return Promise.resolve(options);
            })
                .catch(function () {
                return Promise.reject(_this.getErrorResponse({ response: { status: AWS_AUTH.SIGN_IN_ERROR_UNKNOW } }, 'initOptions'));
            });
        }
    };
    AWSAPIService.prototype.post = function (options) {
        var _this = this;
        //Init options
        return this.initOptions(options)
            .then(function (options) {
            return _this.apiObj.post(options.apiName, options.path, { body: options.body, headers: options.headers })
                .then(function (response) {
                return Promise.resolve(response);
            })
                .catch(function (error) {
                return Promise.reject(_this.getErrorResponse(error, 'post'));
            });
        });
    };
    AWSAPIService.prototype.get = function (options) {
        var _this = this;
        //Init options
        return this.initOptions(options)
            .then(function (options) {
            //Query string params
            if (!options.queryStringParams) {
                options.queryStringParams = {};
            }
            return _this.apiObj.get(options.apiName, options.path, { headers: options.headers, queryStringParameters: options.queryStringParams })
                .then(function (response) {
                return Promise.resolve(response);
            })
                .catch(function (error) {
                return Promise.reject(_this.getErrorResponse(error, 'get'));
            });
        });
    };
    AWSAPIService.prototype.getURL = function (options) {
        var _this = this;
        //Init options
        return this.initOptions(options)
            .then(function (options) {
            console.log(options);
            //Check if there are query string params
            var queryString = '';
            if (options.queryStringParams) {
                queryString += '?';
                for (var key in options.queryStringParams) {
                    queryString += key + '=' + options.queryStringParams[key] + '&';
                }
                //Remove last '&'
                queryString = queryString.slice(0, -1);
            }
            return _this.apiObj.endpoint(options.apiName)
                .then(function (endpoint) {
                console.log('endpoint', endpoint);
                return endpoint + '/' + options.path + queryString;
            });
        });
    };
    AWSAPIService.prototype.getErrorResponse = function (error, origin, code) {
        //Check for error
        var errorBody = {
            code: code ? code : _.get(error, 'code', null),
            description: _.get(error, 'message', null)
        };
        //Set message if error is handled
        switch (code) {
            case AWS_CLASS.RESPONSES.CODES.AUTH_401:
                //Unauthorized
                errorBody.description = 'Unathorized user. Please try again.';
                break;
            case AWS_AUTH.SIGN_IN_ERROR_UNKNOW:
                //No JWT found
                errorBody.description = this.getCouldNotConnectError() + ' ' + 'Please try again later.';
                break;
        }
        return _super.prototype.getErrorResponse.call(this, error, origin, code);
    };
    return AWSAPIService;
}(AWSClass));
export { AWSAPIService };
