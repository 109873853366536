import { NgModule } from '@angular/core';

import { AWSDataEmployeesService } from './aws-data-employees';
import { AWSAPIServiceModule } from '../../helpers/api/aws-api.module';

@NgModule({
  imports: [
    AWSAPIServiceModule,
  ],
  providers: [
    AWSDataEmployeesService,
  ]
})
export class AWSDataEmployeesServiceModule { }