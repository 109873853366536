/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout-header.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng-zorro-antd/menu";
import * as i3 from "ng-zorro-antd/core";
import * as i4 from "../../../../../node_modules/ng-zorro-antd/menu/ng-zorro-antd-menu.ngfactory";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "ng-zorro-antd/icon";
import * as i7 from "../layout-menu/layout-menu.component.ngfactory";
import * as i8 from "../layout-menu/layout-menu.component";
import * as i9 from "../../../services/global-vars/global-vars.service";
import * as i10 from "@angular/forms";
import * as i11 from "../../../services/data/ports/ports.service";
import * as i12 from "../../../services/modal/modal.service";
import * as i13 from "@angular/common";
import * as i14 from "../../helpers/infinite-progress/infinite-progress.component.ngfactory";
import * as i15 from "../../helpers/infinite-progress/infinite-progress.component";
import * as i16 from "../../../../../node_modules/ng-zorro-antd/layout/ng-zorro-antd-layout.ngfactory";
import * as i17 from "ng-zorro-antd/layout";
import * as i18 from "@angular/flex-layout/extended";
import * as i19 from "@angular/flex-layout/core";
import * as i20 from "./layout-header.component";
import * as i21 from "@angular/router";
import * as i22 from "../../../services/media-query/media-query.service";
var styles_LayoutHeaderComponent = [i0.styles];
var RenderType_LayoutHeaderComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LayoutHeaderComponent, data: {} });
export { RenderType_LayoutHeaderComponent as RenderType_LayoutHeaderComponent };
function View_LayoutHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "ul", [["id", "layout-header-user-menu"], ["nz-menu", ""], ["nzMode", "horizontal"], ["nzTheme", "dark"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.NzMenuService, i2.NzMenuService, []), i1.ɵprd(1024, null, i3.NzMenuBaseService, i2.NzMenuServiceFactory, [[3, i3.NzDropdownHigherOrderServiceToken], i2.NzMenuService]), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(5, 1785856, null, 2, i2.NzMenuDirective, [i1.ElementRef, i3.NzMenuBaseService, i3.NzUpdateHostClassService], { nzTheme: [0, "nzTheme"], nzMode: [1, "nzMode"] }, null), i1.ɵqud(603979776, 1, { listOfNzMenuItemDirective: 1 }), i1.ɵqud(603979776, 2, { listOfNzSubMenuComponent: 1 }), (_l()(), i1.ɵeld(8, 0, null, null, 8, "li", [["class", "no-padding"], ["nz-submenu", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSider() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_NzSubMenuComponent_0, i4.RenderType_NzSubMenuComponent)), i1.ɵprd(512, null, i2.NzSubmenuService, i2.NzSubmenuService, [[3, i2.NzSubmenuService], i2.NzMenuService]), i1.ɵprd(512, null, i3.NzUpdateHostClassService, i3.NzUpdateHostClassService, [i1.RendererFactory2]), i1.ɵdid(11, 1818624, [[3, 4], [2, 4]], 2, i2.NzSubMenuComponent, [i1.ElementRef, i3.NzMenuBaseService, i1.ChangeDetectorRef, i2.NzSubmenuService, i3.NzUpdateHostClassService, i5.Platform, [8, null]], null, null), i1.ɵqud(603979776, 3, { listOfNzSubMenuComponent: 1 }), i1.ɵqud(603979776, 4, { listOfNzMenuItemDirective: 1 }), (_l()(), i1.ɵeld(14, 0, null, 0, 2, "span", [["title", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "i", [["class", "no-margin"], ["nz-icon", ""]], null, null, null, null, null)), i1.ɵdid(16, 2834432, null, 0, i6.NzIconDirective, [i6.NzIconService, i1.ElementRef, i1.Renderer2, i5.Platform], { type: [0, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "dark"; var currVal_1 = "horizontal"; _ck(_v, 5, 0, currVal_0, currVal_1); _ck(_v, 11, 0); var currVal_2 = ((_co.siderCollapsed === false) ? "menu-unfold" : "menu-fold"); _ck(_v, 16, 0, currVal_2); }, null); }
function View_LayoutHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-layout-menu", [], [[8, "hidden", 0]], null, null, i7.View_LayoutMenuComponent_0, i7.RenderType_LayoutMenuComponent)), i1.ɵdid(2, 245760, null, 0, i8.LayoutMenuComponent, [i9.GlobalVarsService, i10.FormBuilder, i11.DataPortsService, i12.ModalService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutHeaderComponent_2)), i1.ɵdid(4, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_1 = (_co.mobileLayout === true); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.mobileLayout !== false); _ck(_v, 1, 0, currVal_0); }); }
function View_LayoutHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-infinite-progress", [], null, null, null, i14.View_InfiniteProgressComponent_0, i14.RenderType_InfiniteProgressComponent)), i1.ɵdid(1, 114688, null, 0, i15.InfiniteProgressComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_LayoutHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "nz-header", [["id", "layout-header"]], null, null, null, i16.View_NzHeaderComponent_0, i16.RenderType_NzHeaderComponent)), i1.ɵdid(1, 278528, null, 0, i13.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "small-screen": 0 }), i1.ɵdid(3, 49152, null, 0, i17.NzHeaderComponent, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(4, 933888, null, 0, i18.DefaultClassDirective, [i1.ElementRef, i19.StyleUtils, i19.MediaMarshaller, i1.IterableDiffers, i1.KeyValueDiffers, i1.Renderer2, [6, i13.NgClass]], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(5, { "small-screen": 0 }), (_l()(), i1.ɵeld(6, 0, null, 0, 3, "div", [["id", "layout-header-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["id", "layout-header-logo"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutHeaderComponent_1)), i1.ɵdid(9, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutHeaderComponent_3)), i1.ɵdid(11, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (_co.mobileLayout === true)); _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 5, 0, (_co.mobileLayout === true)); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.user !== null); _ck(_v, 9, 0, currVal_2); var currVal_3 = (_co.navigating === true); _ck(_v, 11, 0, currVal_3); }, null); }
export function View_LayoutHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout-header", [], null, null, null, View_LayoutHeaderComponent_0, RenderType_LayoutHeaderComponent)), i1.ɵdid(1, 245760, null, 0, i20.LayoutHeaderComponent, [i21.Router, i9.GlobalVarsService, i22.MediaQueryService, i11.DataPortsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutHeaderComponentNgFactory = i1.ɵccf("app-layout-header", i20.LayoutHeaderComponent, View_LayoutHeaderComponent_Host_0, {}, {}, []);
export { LayoutHeaderComponentNgFactory as LayoutHeaderComponentNgFactory };
