<div nz-row nzType="flex" nzJustify="center">
  <div nz-col nzXs="24" nzSm="12" nzMd="8">
    <form nz-form id="login-form" [formGroup]="loginForm" (ngSubmit)="performLogin()">
      <nz-form-item>
        <nz-form-control>
          <ng-template #prefixTemplateUser><i nz-icon type="user"></i></ng-template>
          <nz-input-group [nzPrefix]="prefixTemplateUser">
            <input type="text" nz-input formControlName="username" placeholder="Username">
          </nz-input-group>
          <nz-form-explain *ngIf="loginForm.get('username').dirty && loginForm.get('username').errors">
            Please enter your Username
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control>
          <ng-template #prefixTemplatePassword><i nz-icon type="lock"></i></ng-template>
          <nz-input-group [nzPrefix]="prefixTemplatePassword">
            <input type="password" nz-input formControlName="password" placeholder="Password">
          </nz-input-group>
          <nz-form-explain *ngIf="loginForm.get('password').dirty && loginForm.get('password').errors">
            Please enter your Password
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="no-margin">
        <nz-form-control class="text-align-center">
          <button nz-button nzType="primary" [disabled]="!loginForm.valid"><i nz-icon type="login"></i>Log in</button>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</div>