<nz-layout id="layout-main-container">
  <nz-layout>
    <app-layout-header></app-layout-header>

    <nz-content id="layout-content-container" [ngClass]="{'small-screen': mobileLayout === true}">
      <!--<app-layout-breadcrumb></app-layout-breadcrumb>-->

      <div id="layout-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>

    <app-layout-footer></app-layout-footer>
  </nz-layout>

  <!-- Sider -->
  <nz-sider id="layout-sider" [hidden]="siderCollapsed !== false" [(nzCollapsed)]="siderCollapsed" nzCollapsedWidth="0">
    <app-layout-menu [isSideMenu]="true"></app-layout-menu>
  </nz-sider>
</nz-layout>