import { OnInit } from '@angular/core';
var HardLoaderComponent = /** @class */ (function () {
    function HardLoaderComponent() {
        this.content = 'Loading';
    }
    HardLoaderComponent.prototype.ngOnInit = function () {
    };
    return HardLoaderComponent;
}());
export { HardLoaderComponent };
