import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NzMessageService, NzMessageDataOptions } from 'ng-zorro-antd';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as _ from 'lodash';

import { MediaQueryService } from 'src/app/services/media-query/media-query.service';
import { IPort } from '../layout/layout-header/layout-header.component';
import { GlobalVarsService } from 'src/app/services/global-vars/global-vars.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { DataHomeContentService } from 'src/app/services/data/home-content/home-content.service';

export const QUILL_COLORS = [
  '#000000',
  '#666666',
  '#e0e0e0',
  '#ffffff',
  '#c63527',
  '#941100',
  '#692122',
  '#535f6a',
  '#001489',
  '#a0c3e3',
  '#0ba247',
  '#87f3b2',
  '#ffce00',
  '#fff1b8',
];

export const QUILL_MODULES = {
  toolbar: [
    //Font size
    [
      { 'header': [1, 2, 3, 4, false] }
    ],

    //Font format
    [
      'bold',
      'italic',
      'underline',
      //'strike',
    ],

    //Link
    ['link'],

    //Colors
    [
      { 'color': QUILL_COLORS },
      { 'background': QUILL_COLORS }
    ],

    //Clean format
    ['clean'],
  ]
};

export const QUILL_FORMATS = [
  'header',
  'bold',
  'italic',
  'underline',
  'link',
  'color',
  'background'
];

export const PREVIEW_DEVICE_TABLET = 'tablet';
export const PREVIEW_DEVICE_MOBILE = 'mobile';
export const PREVIEW_ORIENTATION_LANDSCAPE = 'landscape';
export const PREVIEW_ORIENTATION_PORTRAIT = 'portrait';
export const FORM_CHANGE_DELAY = 125;

export interface IContact {
  id: number,
  name: string,
  jobTitle: string,
  phone: string,
  email: string,
  empId: number,
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  homeForm: FormGroup;
  primaryChangesSubscription: Subscription;
  secondaryChangesSubscription: Subscription;
  deviceChangesSubscription: Subscription;
  orientationChangesSubscription: Subscription;

  primaryPreview: SafeHtml = null;
  secondaryPreview: SafeHtml = null;

  primaryQuillModules: any;
  primaryQuillFormats: string[];
  secondaryQuillModules: any;
  secondaryQuillFormats: string[];

  mobileLayout: boolean = true;
  mobileLayoutSubscription: Subscription;

  previewDevices = [
    { id: PREVIEW_DEVICE_MOBILE, name: 'Mobile' },
    { id: PREVIEW_DEVICE_TABLET, name: 'Tablet' },
  ];

  previewOrientations = [
    { id: PREVIEW_ORIENTATION_PORTRAIT, name: 'Portrait' },
    { id: PREVIEW_ORIENTATION_LANDSCAPE, name: 'Landscape' },
  ];

  singleColumnPreview: boolean = true;
  previewContainerClasses = {};

  loading: boolean = true;

  port: IPort = null;
  portSubscription: Subscription;

  homeContent: any = {};

  contacts: IContact[] = [];
  editContactId: number | null = null;
  contactForm: FormGroup;

  unsavedData: boolean = false;
  unsavedDataSubscription: Subscription;

  constructor(
    public formBuilder: FormBuilder,
    public sanitizer: DomSanitizer,
    public mediaQueryService: MediaQueryService,
    public globalVarsService: GlobalVarsService,
    public dataHomeContentService: DataHomeContentService,
    public modalService: ModalService,
    public renderer: Renderer2,
    public nzMessageService: NzMessageService,
  ) { }

  ngOnInit() {
    //Set modules
    this.primaryQuillModules = JSON.parse(JSON.stringify(QUILL_MODULES));
    this.secondaryQuillModules = JSON.parse(JSON.stringify(QUILL_MODULES));
    this.secondaryQuillModules.toolbar[0] = [{ 'header': [2, 3, 4, false] }];

    //Set formats
    this.primaryQuillFormats = QUILL_FORMATS;
    this.secondaryQuillFormats = QUILL_FORMATS;

    //Set form
    this.setForm();
    this.setContactForm();

    //Get mobileLayout value and subscribe to changes
    this.mobileLayout = this.mediaQueryService.getMobileLayout();
    this.mobileLayoutSubscription = this.mediaQueryService.mobileLayoutChange
      .subscribe((mobileLayout: boolean) => {
        this.mobileLayout = mobileLayout;
      });

    //Get port value and subscribe to changes
    this.port = this.globalVarsService.getSelectedPort();
    this.portSubscription = this.globalVarsService.selectedPortStream
      .subscribe((port: IPort) => {
        if (port) {
          this.port = port;
          this.getHomeContent();
        }
      });
    
    //Get unsaved cahnges value and subscribe to changes
    this.unsavedData = this.globalVarsService.getUnsavedData();
    this.unsavedDataSubscription = this.globalVarsService.unsavedDataStream
      .subscribe((unsavedData: boolean) => {
        this.unsavedData = unsavedData;
      });
  }

  ngOnDestroy() {
    //Unsubscribe from changes on the primary content
    if (this.primaryChangesSubscription) {
      this.primaryChangesSubscription.unsubscribe();
    }

    //Unsubscribe from changes on the secondary content
    if (this.secondaryChangesSubscription) {
      this.secondaryChangesSubscription.unsubscribe();
    }

    //Unsubscribe from changes on device preview
    if (this.deviceChangesSubscription) {
      this.deviceChangesSubscription.unsubscribe();
    }

    //Unsubscribe from changes on orientation preview
    if (this.orientationChangesSubscription) {
      this.orientationChangesSubscription.unsubscribe();
    }

    //Unsubscribe from mobile layout changes
    if (this.mobileLayoutSubscription) {
      this.mobileLayoutSubscription.unsubscribe();
    }

    //Unsubscribe from port changes
    if (this.portSubscription) {
      this.portSubscription.unsubscribe();
    }

    //Unsubscribe from unsaved data changes
    if (this.unsavedDataSubscription) {
      this.unsavedDataSubscription.unsubscribe();
    }
  }

  setForm() {
    this.homeForm = this.formBuilder.group({
      primary: [null],
      secondary: [null],
      previewDevice: [this.previewDevices[0].id],
      previewOrientation: [this.previewOrientations[0].id],
    });
    this.setPreviewClasses();

    //Subscribe to changes on the primary content
    this.primaryChangesSubscription = this.homeForm.get('primary').valueChanges
      .pipe(debounceTime(FORM_CHANGE_DELAY))
      .subscribe((value) => {
        this.primaryPreview = this.getSafeHtmlValue(value);
        this.setChangesNotSaved(true);
      });

    //Subscribe to changes on the secondary content
    this.secondaryChangesSubscription = this.homeForm.get('secondary').valueChanges
      .pipe(debounceTime(FORM_CHANGE_DELAY))
      .subscribe((value) => {
        this.secondaryPreview = this.getSafeHtmlValue(value);
        this.setChangesNotSaved(true);
      });

    //Subscribe to changes on device preview
    this.deviceChangesSubscription = this.homeForm.get('previewDevice').valueChanges
      .subscribe((value) => {
        //Set single column preview
        this.singleColumnPreview = value === PREVIEW_DEVICE_MOBILE && this.homeForm.get('previewOrientation').value === PREVIEW_ORIENTATION_PORTRAIT;

        //Set classes
        this.setPreviewClasses();
      });
    
    //Subscribe to changes on orientation preview
    this.orientationChangesSubscription = this.homeForm.get('previewOrientation').valueChanges
      .subscribe((value) => {
        //Set single column preview
        this.singleColumnPreview = value === PREVIEW_ORIENTATION_PORTRAIT && this.homeForm.get('previewDevice').value === PREVIEW_DEVICE_MOBILE;

        //Set classes
        this.setPreviewClasses();
      });
  }

  getSafeHtmlValue(value: string): SafeHtml {
    let safeHtmlValue: SafeHtml = null;

    if (value && value !== '') {
      safeHtmlValue = this.sanitizer.bypassSecurityTrustHtml(value);
    }

    return safeHtmlValue;
  }

  setPreviewClasses() {
    this.previewContainerClasses = {
      'preview-container-tablet': this.homeForm.controls.previewDevice.value === PREVIEW_DEVICE_TABLET,
      'preview-container-tablet-portrait': this.homeForm.controls.previewDevice.value === PREVIEW_DEVICE_TABLET && this.homeForm.controls.previewOrientation.value === PREVIEW_ORIENTATION_PORTRAIT,
      'preview-container-tablet-landscape': this.homeForm.controls.previewDevice.value === PREVIEW_DEVICE_TABLET && this.homeForm.controls.previewOrientation.value === PREVIEW_ORIENTATION_LANDSCAPE,
      'preview-container-mobile': this.homeForm.controls.previewDevice.value === PREVIEW_DEVICE_MOBILE,
      'preview-container-mobile-portrait': this.homeForm.controls.previewDevice.value === PREVIEW_DEVICE_MOBILE && this.homeForm.controls.previewOrientation.value === PREVIEW_ORIENTATION_PORTRAIT,
      'preview-container-mobile-landscape': this.homeForm.controls.previewDevice.value === PREVIEW_DEVICE_MOBILE && this.homeForm.controls.previewOrientation.value === PREVIEW_ORIENTATION_LANDSCAPE,
    };
  }

  setPreviewValue(value: string, isPrimary?: boolean) {
    if (isPrimary) {
      //Primary
      this.primaryPreview = this.getSafeHtmlValue(value);
      this.homeForm.controls.primary.setValue(value, { emitEvent: false });
    } else {
      //Secondary
      this.secondaryPreview = this.getSafeHtmlValue(value);
      this.homeForm.controls.secondary.setValue(value, { emitEvent: false });
    }
  }

  clearPreviewValue(isPrimary?: boolean) {
    if (isPrimary) {
      this.setPreviewValue(null, true);
    } else {
      this.setPreviewValue(null);
    }
    this.setChangesNotSaved(true);
  }

  getHomeContent() {
    if (this.port) {
      this.setPreviewValue(null, true);
      this.setPreviewValue(null);
      this.contacts = [];
      this.loading = true;
      this.setChangesNotSaved(false);

      this.dataHomeContentService.get()
        .then((homeContent) => {
          //Set home content
          this.homeContent = homeContent;

          //Get port content
          const portContent = this.homeContent[this.port.code];
          if (portContent) {
            //Set individual contents
            this.setPreviewValue(portContent.primary[0].content['en-AU'], true);
            this.setPreviewValue(portContent.secondary[0].content['en-AU']);

            //Set contacts
            this.contacts = portContent.contacts ? portContent.contacts : [];
          }

          this.loading = false;
        })
        .catch((error) => {
          console.log('error', error);
          this.loading = false;
        });
    }
  }

  saveHomeContent() {
    this.setChangesNotSaved(false);

    //Check if object has been initialized for the current port
    if (!this.homeContent[this.port.code]) {
      this.homeContent[this.port.code] = {
        primary: [{ 'id': '1', 'content': { 'en-AU': '' } }],
        secondary: [{ 'id': '2', 'content': { 'en-AU': '' } }],
        contacts: [],
      };
    }

    //Set individual contents
    this.homeContent[this.port.code].primary[0].content['en-AU'] = this.homeForm.controls.primary.value;
    this.homeContent[this.port.code].secondary[0].content['en-AU'] = this.homeForm.controls.secondary.value;

    //Set contacts
    this.homeContent[this.port.code].contacts = this.contacts;

    this.loading = true;
    return this.dataHomeContentService.set(this.homeContent)
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
        this.setChangesNotSaved(true);
      });
  }

  confirmCancel() {
    this.modalService.presentConfirmAlert({
      title: 'Confirm cancel',
      content: 'Are you sure you want cancel the current changes?',
      okButtonText: 'Yes',
      okButtonFunction: () => {
        this.getHomeContent();
        this.setChangesNotSaved(false);
      },
    });
  }

  setContactForm(contact?: IContact) {
    this.contactForm = this.formBuilder.group({
      name: [contact ? contact.name : null, [Validators.required]],
      jobTitle: [contact ? contact.jobTitle : null],
      phone: [contact ? contact.phone : null],
      email: [contact ? contact.email : null],
      empId: [contact ? contact.empId : null],
    });
  }

  addContact() {
    const id: number = 0;

    this.contacts = [
      ...this.contacts,
      {
        id: id,
        name: '',
        jobTitle: '',
        phone: '',
        email: '',
        empId: null,
      }
    ];

    setTimeout(() => { this.editContact(id) }, 0);
  }

  getNextId(): number {
    let maxId: number = 0;

    const contact = _.maxBy(this.contacts, function (c) { return c.id; });
    if (contact) {
      maxId = contact.id + 1;
    } else {
      maxId++;
    }

    return maxId;
  }

  editContact(id: number, event?: MouseEvent): void {
    /*if (event) {
      event.preventDefault();
      event.stopPropagation();
    }*/

    this.editContactId = id;

    //Populate form
    const contact = _.find(this.contacts, { id: id });
    if (contact) {
      this.setContactForm(contact);
    }

    setTimeout(() => { this.renderer.selectRootElement('#inputContactName').focus() }, 0);

    this.setChangesNotSaved(true);
  }

  deleteContact(id: number): void {
    this.contacts = _.remove(this.contacts, (c) => { return c.id !== id; });
    this.setChangesNotSaved(true);
  }

  saveContact(id: number): void {
    let contact = _.find(this.contacts, { id: id });
    if (contact) {
      contact.name = this.contactForm.get('name').value;
      contact.jobTitle = this.contactForm.get('jobTitle').value;
      contact.phone = this.contactForm.get('phone').value;
      contact.email = this.contactForm.get('email').value;
      contact.empId = this.contactForm.get('empId').value;

      //Check if it's new contact
      if (contact.id === 0) {
        contact.id = this.getNextId();
      }
    }

    this.editContactId = null;
  }

  cancelContact(id: number): void {
    this.editContactId = null;
    this.setContactForm();
    //Check if it's new contact
    if (id === 0) {
      this.deleteContact(id);
    }
  }

  setChangesNotSaved(value: boolean) {
    if (value) {
      if (!this.unsavedData) {
        const options: NzMessageDataOptions = {
          nzDuration: 0
        };

        this.nzMessageService.info('Changes are not saved yet.', options);
        this.unsavedData = true;
        this.globalVarsService.setUnsavedData(this.unsavedData);
      }
    } else {
      this.nzMessageService.remove();
      this.unsavedData = false;
      this.globalVarsService.setUnsavedData(this.unsavedData);
    }
  }

  dropContact(event: CdkDragDrop<IContact[]>): void {
    moveItemInArray(this.contacts, event.previousIndex, event.currentIndex);
    this.contacts = [...this.contacts];
    this.setChangesNotSaved(true);
  }
}