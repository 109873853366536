import { NgModule } from '@angular/core';

import { DataPortsService } from './ports.service';

@NgModule({
  imports: [  ],
  providers: [
    DataPortsService,
  ]
})
export class DataPortsServiceModule { }