import { Injectable } from '@angular/core';

import { NzModalService, NzModalRef, ModalOptionsForService } from 'ng-zorro-antd';
import { HardLoaderComponent } from './components/hard-loader/hard-loader.component';

export interface IModalHardLoaderOptions {
  content?: string,
}

export const MODAL_ALERT_TYPE_INFO = 'info';
export const MODAL_ALERT_TYPE_SUCCESS = 'success';
export const MODAL_ALERT_TYPE_ERROR = 'error';
export const MODAL_ALERT_TYPE_WARNING = 'warning';
export const MODAL_ALERT_TYPE_CONFIRM = 'confirm';

export interface IModalAlertOptions {
  type?: string,
  title?: string,
  content?: string,
  okButtonText?: string,
  okButtonFunction?: (instance: any) => false | void | {} | Promise<false | void | {}>,
  cancelButtonFunction?: (instance: any) => false | void | {} | Promise<false | void | {}>,
}

@Injectable()
export class ModalService {
  hardLoaderModal: NzModalRef = null;
  alertModal: NzModalRef = null;

  constructor(
    public nzModalService: NzModalService,
  ) { }

  initOptions(options?: IModalHardLoaderOptions): IModalHardLoaderOptions {
    if (!options) {
      options = <IModalHardLoaderOptions>{};
    }

    return options;
  }

  presentHardLoader(options?: IModalHardLoaderOptions) {
    options = this.initOptions(options);

    this.hardLoaderModal = this.nzModalService.create({
      nzTitle: null,
      nzContent: HardLoaderComponent,
      nzComponentParams: { content: options.content ? options.content : 'Loading...' },
      nzMask: true,
      nzClosable: false,
      nzMaskClosable: false,
      nzFooter: null,
    });
  }

  updateHardLoaderContent(content: string) {
    if (this.hardLoaderModal) {
      let hardLoaderContent: HardLoaderComponent = this.hardLoaderModal.getContentComponent();
      if (hardLoaderContent) {
        hardLoaderContent.content = content;
      }
    }
  }

  dismissHardLoader() {
    if (this.hardLoaderModal) {
      this.hardLoaderModal.close();
      this.hardLoaderModal = null;
    }
  }

  presentErrorAlert(options?: IModalAlertOptions) {
    options = this.initOptions(options);
    options.type = MODAL_ALERT_TYPE_ERROR;
    this.presentAlert(options);
  }

  presentWarningAlert(options?: IModalAlertOptions) {
    options = this.initOptions(options);
    options.type = MODAL_ALERT_TYPE_WARNING;
    this.presentAlert(options);
  }

  presentConfirmAlert(options?: IModalAlertOptions) {
    options = this.initOptions(options);
    options.type = MODAL_ALERT_TYPE_CONFIRM;
    this.presentAlert(options);
  }

  presentAlert(options?: IModalAlertOptions) {
    options = this.initOptions(options);

    let modalOptions: ModalOptionsForService = {
      nzTitle: options.title ? options.title : null,
      nzContent: options.content ? options.content : null,
      nzClosable: false,
      nzMaskClosable: false,
      nzOkText: options.okButtonText ? options.okButtonText : 'Ok',
    };

    switch (options.type) {
      case MODAL_ALERT_TYPE_SUCCESS:
        //Success
        modalOptions.nzTitle = modalOptions.nzTitle === null ? 'Success' : modalOptions.nzTitle;
        this.alertModal = this.nzModalService.success(modalOptions);
        break;
      case MODAL_ALERT_TYPE_ERROR:
        //Error
        modalOptions.nzTitle = modalOptions.nzTitle === null ? 'Error' : modalOptions.nzTitle;
        this.alertModal = this.nzModalService.error(modalOptions);
        break;
      case MODAL_ALERT_TYPE_WARNING:
        //Warning
        modalOptions.nzTitle = modalOptions.nzTitle === null ? 'Warning' : modalOptions.nzTitle;
        this.alertModal = this.nzModalService.warning(modalOptions);
        break;
      case MODAL_ALERT_TYPE_CONFIRM:
        //Confirm
        modalOptions.nzTitle = modalOptions.nzTitle === null ? 'Confirm' : modalOptions.nzTitle;
        modalOptions.nzOnOk = options.okButtonFunction ? options.okButtonFunction : () => { };
        modalOptions.nzOnCancel = options.cancelButtonFunction ? options.cancelButtonFunction : () => { };
        this.alertModal = this.nzModalService.confirm(modalOptions);
        break;
      default:
        //Info
        modalOptions.nzTitle = modalOptions.nzTitle === null ? 'Info' : modalOptions.nzTitle;
        this.alertModal = this.nzModalService.info(modalOptions);
        break;
    }
  }

  dismissAlert() {
    if (this.alertModal) {
      this.alertModal.close();
      this.alertModal = null;
    }
  }
}
