<form nz-form id="home-form" [formGroup]="homeForm" [nzLayout]="'vertical'">
  <app-loader [hidden]="!loading"></app-loader>

  <div [hidden]="loading" nz-row>
    <div nz-col nzXs="24" nzXl="14" [ngClass]="{'padding-right': !mobileLayout, 'margin-bottom': mobileLayout}">
      <nz-card nzTitle="Home Page Content" [nzExtra]="extraContentTemplate">
        <ng-template #extraContentTemplate>
          <button nz-button nzType="primary" class="margin-right" (click)="saveHomeContent()" [disabled]="editContactId !== null">
            <i nz-icon type="save"></i>Save
          </button>

          <button nz-button nzType="default" (click)="confirmCancel()">
            <i nz-icon type="undo"></i>Cancel
          </button>
        </ng-template>

        <div nz-row>
          <div nz-col nzXs="24" class="margin-bottom">
            <nz-card nzTitle="Primary" [nzExtra]="extraPrimaryTemplate">
              <ng-template #extraPrimaryTemplate>
                <button *ngIf="homeForm.controls.primary.value" nz-button nzType="default" (click)="clearPreviewValue(true)">
                  <i nz-icon type="close-circle"></i>Clear
                </button>
              </ng-template>

              <quill-editor formControlName="primary" [modules]="primaryQuillModules" [formats]="primaryQuillFormats"></quill-editor>
            </nz-card>
          </div>

          <div nz-col nzXs="24" class="margin-bottom">
            <nz-card nzTitle="Secondary" [nzExtra]="extraSecondaryTemplate">
              <ng-template #extraSecondaryTemplate>
                <button *ngIf="homeForm.controls.secondary.value" nz-button nzType="default" (click)="clearPreviewValue()">
                  <i nz-icon type="close-circle"></i>Clear
                </button>
              </ng-template>

              <quill-editor formControlName="secondary" [modules]="secondaryQuillModules" [formats]="secondaryQuillFormats"></quill-editor>
            </nz-card>
          </div>

          <div nz-col nzXs="24">
            <form nz-form id="contact-form" [formGroup]="contactForm" [nzLayout]="'vertical'">
              <nz-card id="contacts-list-card" nzTitle="Contacts" [nzExtra]="extraContactsTemplate">
                <ng-template #extraContactsTemplate>
                  <button nz-button (click)="addContact()" nzType="primary" [disabled]="editContactId !== null">
                    <i nz-icon type="plus"></i>Add
                  </button>
                </ng-template>

                <nz-table #contactsTable id="contacts-table" nzBordered [nzData]="contacts" [nzShowPagination]="false" [nzNoResult]="'No contacts'">
                  <thead>
                    <tr>
                      <th colspan="2">Name</th>
                      <th>Job title</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Emp. Id</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody cdkDropList (cdkDropListDropped)="dropContact($event)">
                    <tr *ngFor="let contact of contactsTable.data" class="editable-row" cdkDrag>
                      <td class="order-container">
                        <i nz-icon type="drag"></i>
                      </td>

                      <td>
                        <div *cdkDragPreview>
                          {{ contact.name }}
                        </div>
                        <div class="editable-cell" *ngIf="editContactId !== contact.id; else editNameTpl">
                          <div class="editable-cell-value-wrap">
                            {{ contact.name }}
                          </div>
                        </div>
                        <ng-template #editNameTpl>
                          <input id="inputContactName" type="text" nz-input formControlName="name" />
                          <nz-form-explain *ngIf="contactForm.get('name').dirty && contactForm.get('name').errors">
                            Please enter a name
                          </nz-form-explain>
                        </ng-template>
                      </td>

                      <td>
                        <div class="editable-cell" *ngIf="editContactId !== contact.id; else editJobTitleTpl">
                          <div class="editable-cell-value-wrap">
                            {{ contact.jobTitle }}
                          </div>
                        </div>
                        <ng-template #editJobTitleTpl>
                          <input type="text" nz-input formControlName="jobTitle" />
                        </ng-template>
                      </td>

                      <td>
                        <div class="editable-cell" *ngIf="editContactId !== contact.id; else editPhoneTpl">
                          <div class="editable-cell-value-wrap">
                            {{ contact.phone }}
                          </div>
                        </div>
                        <ng-template #editPhoneTpl>
                          <input type="text" nz-input formControlName="phone" />
                        </ng-template>
                      </td>

                      <td>
                        <div class="editable-cell" *ngIf="editContactId !== contact.id; else editEmailTpl">
                          <div class="editable-cell-value-wrap">
                            {{ contact.email }}
                          </div>
                        </div>
                        <ng-template #editEmailTpl>
                          <input type="text" nz-input formControlName="email" />
                        </ng-template>
                      </td>

                      <td>
                        <div class="editable-cell" *ngIf="editContactId !== contact.id; else editEmpIdTpl">
                          <div class="editable-cell-value-wrap">
                            {{ contact.empId }}
                          </div>
                        </div>
                        <ng-template #editEmpIdTpl>
                          <input type="number" nz-input formControlName="empId" />
                        </ng-template>
                      </td>

                      <td class="text-align-center no-wrap action-container">
                        <ng-container *ngIf="editContactId !== contact.id">
                          <span nz-tooltip nzTitle="Edit">
                            <button nz-button nzType="primary" nzShape="circle" (click)="editContact(contact.id, $event)" [disabled]="editContactId !== null">
                              <i nz-icon type="edit"></i>
                            </button>
                          </span>

                          <span nz-tooltip nzTitle="Delete">
                            <a nz-popconfirm nzTitle="Are you sure you want to delete this contact?" (nzOnConfirm)="deleteContact(contact.id)" class="margin-left-10">
                              <button nz-button nzType="default" nzShape="circle"><i nz-icon type="delete"></i></button>
                            </a>
                          </span>
                        </ng-container>

                        <ng-container *ngIf="editContactId === contact.id">
                          <span nz-tooltip nzTitle="Apply">
                            <button nz-button nzType="primary" nzShape="circle" (click)="saveContact(contact.id, $event)" [disabled]="!contactForm.valid">
                              <i nz-icon type="check"></i>
                            </button>
                          </span>

                          <span nz-tooltip nzTitle="Discard">
                            <a nz-popconfirm nzTitle="Are you sure you want to discard changes?" (nzOnConfirm)="cancelContact(contact.id)" class="margin-left-10">
                              <button nz-button nzType="default" nzShape="circle"><i nz-icon type="undo"></i></button>
                            </a>
                          </span>
                        </ng-container>
                      </td>
                    </tr>
                  </tbody>
                </nz-table>
              </nz-card>
            </form>
          </div>
        </div>
      </nz-card>
    </div>

    <div nz-col nzXs="24" nzXl="10">
      <nz-card id="preview-card" nzTitle="Preview">
        <div nz-row nzType="flex" nzJustify="center">
          <div nz-col nzXs="24">
            <div nz-row>
              <div nz-col nzXs="24" nzSm="12" class="padding-right">
                <nz-form-item>
                  <nz-form-label nzFor="previewDevice">Device</nz-form-label>
                  <nz-form-control>
                    <nz-radio-group formControlName="previewDevice">
                      <label *ngFor="let device of previewDevices" nz-radio-button [nzValue]="device.id">{{ device.name }}</label>
                    </nz-radio-group>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzXs="24" nzSm="12">
                <nz-form-item>
                  <nz-form-label nzFor="previewOrientation">Orientation</nz-form-label>
                  <nz-form-control>
                    <nz-radio-group formControlName="previewOrientation">
                      <label *ngFor="let orientation of previewOrientations" nz-radio-button [nzValue]="orientation.id">{{ orientation.name }}</label>
                    </nz-radio-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>

          <div nz-col nzXs="24">
            <div class="preview-container" [ngClass]="previewContainerClasses">
              <div class="content">
                <div *ngIf="primaryPreview" nz-col [nzXs]="(singleColumnPreview || !secondaryPreview) ? 24 : 16"
                  [ngClass]="{'padding-right': !singleColumnPreview, 'margin-bottom': singleColumnPreview}">
                  <nz-card class="preview-card" [ngClass]="{'text-placeholder': !primaryPreview}" [innerHtml]="primaryPreview"> </nz-card>
                </div>

                <div *ngIf="secondaryPreview" nz-col [nzXs]="(singleColumnPreview || !primaryPreview) ? 24 : 8">
                  <nz-card class="preview-card" [ngClass]="{'text-placeholder': !secondaryPreview}" [innerHtml]="secondaryPreview"> </nz-card>
                </div>

                <div *ngIf="contacts.length > 0" class="margin-top" nz-col nzXs="24">
                  <nz-card class="preview-contacts-card" nzTitle="Contacts">
                    <div nz-row>
                      <div *ngFor="let contact of contacts" nz-col class="img-wrapper text-align-center" [nzXs]="(singleColumnPreview) ? 24 : 12">
                        <img src="assets/imgs/user_card_3.png">
                      </div>
                    </div>
                  </nz-card>
                </div>

                <div *ngIf="!primaryPreview && !secondaryPreview" nz-col nzXs="24">
                  <nz-card class="preview-card" class="text-placeholder">
                    Start typing in the editors to preview content...
                  </nz-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nz-card>
    </div>
  </div>
</form>