import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { AWSDataHomeContentService } from '../../aws/data/home-content/aws-data-home-content';
import { AWS_CLASS } from '../../aws/aws-class';
import { IAWSAPIRequestOpts } from '../../aws/helpers/api/aws-api';

@Injectable({
  providedIn: 'root'
})
export class DataHomeContentService {
  constructor(
    public AWSDataHomeContentService: AWSDataHomeContentService,
  ) { }

  get(): Promise<any> {
    return this.AWSDataHomeContentService.get()
      .then((response) => {
        console.log('response', response);
        if (response.status === AWS_CLASS.RESPONSES.STATUSES.SUCCESS) {
          //return Promise.resolve(JSON.parse(response.body));
          return Promise.resolve(response.body);
        }
      })
      .catch((error) => {
        console.log('error', error);
        return Promise.reject(error);
      });
  }

  set(homeContent: string): Promise<any> {
    const setOpts: IAWSAPIRequestOpts = {
      body: { 'file_body': homeContent, }
    };

    return this.AWSDataHomeContentService.set(setOpts)
      .then(() => {
        return Promise.resolve(true);
      })
      .catch((error) => {
        console.log('error', error);
        return Promise.reject(error);
      });
  }
}