import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

import { GlobalVarsService, EVENTS } from 'src/app/services/global-vars/global-vars.service';
import { MediaQueryService } from 'src/app/services/media-query/media-query.service';
import { IEmployee } from 'src/app/services/aws/helpers/auth/aws-auth';
import { DataPortsService, IDataPort } from 'src/app/services/data/ports/ports.service';

export interface IPort extends IDataPort { }

@Component({
  selector: 'app-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutHeaderComponent implements OnInit, OnDestroy {
  navigating: boolean = true;
  routerEventsSubscription: Subscription;

  events: Subscription;
  siderCollapsed: boolean = true;

  mobileLayout: boolean = true;
  mobileLayoutStream: Subscription;

  user: IEmployee = null;
  userSubscription: Subscription;

  constructor(
    public router: Router,
    public globalVarsService: GlobalVarsService,
    public mediaQueryService: MediaQueryService,
    public dataPortsService: DataPortsService,
  ) { }

  ngOnInit() {
    //Get sider collapsed value
    this.siderCollapsed = this.globalVarsService.getSiderCollapsed();

    //Get mobileLayout value and subscribe to changes
    this.mobileLayout = this.mediaQueryService.getMobileLayout();
    this.mobileLayoutStream = this.mediaQueryService.mobileLayoutChange
      .subscribe((mobileLayout: boolean) => {
        this.mobileLayout = mobileLayout;
      });

    //Get user value and subscribe to changes
    this.user = this.globalVarsService.getUser();
    this.userSubscription = this.globalVarsService.userStream
      .subscribe((user: IEmployee) => {
        this.user = user;
      });

    //Subscribe to events
    this.events = this.globalVarsService.eventStream
      .subscribe((event: string) => {
        //Process event
        switch (event) {
          case EVENTS.TOGGLE_SIDER:
            this.toggleSider();
            break;
        }
      });

    //Subscribe to router events
    this.routerEventsSubscription = this.router.events
      .subscribe((event: RouterEvent) => {
        if (event instanceof NavigationStart) {
          this.navigating = true;
        } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
          this.navigating = false;
        }
      });
  }

  ngOnDestroy() {
    //Unsubscribe from mobileLayout changes
    if (this.mobileLayoutStream) {
      this.mobileLayoutStream.unsubscribe();
    }

    //Unsubscribe from user changes
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    //Unsubscribe from events
    if (this.events) {
      this.events.unsubscribe();
    }

    //Unsubscribe from router events
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  toggleSider() {
    this.siderCollapsed = !this.siderCollapsed;
    this.globalVarsService.setSiderCollapsed(this.siderCollapsed);
  }
}
