import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import * as _ from 'lodash';

import { IAWSResponse, AWSClass } from '../../aws-class';
import { ModalService } from '../../../modal/modal.service';
import { AWSAPIService, IAWSAPIRequestOpts } from '../../helpers/api/aws-api';

@Injectable()
export class AWSDataHomeContentService extends AWSClass {
  constructor(
    public AWSAPIService: AWSAPIService,
    public modalService: ModalService,
  ) {
    //Populate alert service
    super(modalService);
  }

  initOptions(options?: IAWSAPIRequestOpts): IAWSAPIRequestOpts {
    const opts: IAWSAPIRequestOpts = {
      path: _.get(options, 'path', environment.api.paths.homeContent),
      body: _.get(options, 'body', {}),
    };

    return opts;
  }

  get(options?: IAWSAPIRequestOpts): Promise<IAWSResponse> {
    return this.AWSAPIService.get(this.initOptions(options))
      .then((apiResponse) => {
        return this.handleResponse(apiResponse, 'dataHomeContent get');
      })
      .catch((apiError) => {
        return Promise.reject(this.getErrorResponse(apiError, 'dataHomeContent get'));
      });
  }

  set(options?: IAWSAPIRequestOpts): Promise<IAWSResponse> {
    return this.AWSAPIService.post(this.initOptions(options))
      .then((apiResponse) => {
        return this.handleResponse(apiResponse, 'dataHomeContent set');
      })
      .catch((apiError) => {
        return Promise.reject(this.getErrorResponse(apiError, 'dataHomeContent set'));
      });
  }
}