import { Injectable, EventEmitter } from '@angular/core';

import { IEmployee } from '../aws/helpers/auth/aws-auth';
import { IPort } from 'src/app/components/layout/layout-header/layout-header.component';
import { DataPortsService } from '../data/ports/ports.service';

//Events
export const EVENTS = {
  LOAD_DATA: 'event::load_data',
  LOGOUT: 'event::logout',
  TOGGLE_SIDER: 'event::toggle_sider',
  SELECTED_PORT: 'event::selected_port',
  PORTS: 'event::ports',
};

export interface IEvent { 
  name: string,
  data?: any,
}

@Injectable()
export class GlobalVarsService {
  //Used to detect when the sider is collapsed
  siderCollapsed: boolean = true;
  siderCollapsedStream: EventEmitter<boolean> = new EventEmitter();

  //Used to detect the current URL
  currentURL: string = '';
  currentURLStream: EventEmitter<string> = new EventEmitter();

  //Used to trigger events
  event: IEvent = null;
  eventStream: EventEmitter<IEvent> = new EventEmitter();

  //Used to keep the current user
  user: IEmployee = null;
  userStream: EventEmitter<IEmployee> = new EventEmitter();

  //Used to keep all available ports
  ports: IPort[] = [];
  portsStream: EventEmitter<IPort[]> = new EventEmitter();

  //Used to keep the selected port
  selectedPort: IPort = null;
  selectedPortStream: EventEmitter<IPort> = new EventEmitter();

  //Used to detect when there are unsaved data
  unsavedData: boolean = false;
  unsavedDataStream: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public dataPortsService: DataPortsService,
  ) { }

  getSiderCollapsed(): boolean {
    return this.siderCollapsed;
  }

  setSiderCollapsed(value: boolean): void {
    this.siderCollapsed = value;
    this.siderCollapsedStream.emit(this.siderCollapsed);
  }

  getCurrentURL(): string {
    return this.currentURL;
  }

  setCurrentURL(value: string): void {
    this.currentURL = value;
    this.currentURLStream.emit(this.currentURL);
  }

  getEvent(): IEvent {
    return this.event;
  }

  setEvent(value: IEvent): void {
    this.event = value;
    this.eventStream.emit(this.event);
  }

  getUser(): IEmployee {
    return this.user;
  }

  setUser(value: IEmployee): void {
    this.user = value;
    this.userStream.emit(this.user);
  }

  getPorts(): IPort[] {
    return this.ports;
  }

  setPorts(value: IPort[]): void {
    this.ports = value;
    this.portsStream.emit(this.ports);
  }

  getSelectedPort(): IPort {
    return this.selectedPort;
  }

  setSelectedPort(value: IPort): void {
    this.selectedPort = value;
    this.selectedPortStream.emit(this.selectedPort);

    if (value !== null) {
      this.dataPortsService.setLastPort(this.selectedPort);
    } else {
      this.dataPortsService.deleteLastPort();
    }
  }

  getUnsavedData(): boolean {
    return this.unsavedData;
  }

  setUnsavedData(value: boolean): void {
    this.unsavedData = value;
    this.unsavedDataStream.emit(this.unsavedData);
  }
}
