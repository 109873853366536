import { NgModule } from '@angular/core';

//AWS Amplify module and service
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';

import { AWSAuthService } from './aws-auth';

@NgModule({
  imports: [
    AmplifyAngularModule,
  ],
  providers: [
    AmplifyService,
    AWSAuthService
  ]
})
export class AWSAuthServiceModule { }