import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';

import { AWSAuthService } from '../services/aws/helpers/auth/aws-auth';
import { APP_ROUTES } from '../app-routes';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    public router: Router,
    public AWSAuthService: AWSAuthService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.AWSAuthService.isValidSession()
      .then(() => {
        return true;
      })
      .catch(() => {
        this.router.navigate([APP_ROUTES.login], { queryParams: { skipCheckSession: true, returnUrl: state.url } });
        return false;
      });
  }
}