import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GlobalVarsService, EVENTS } from './services/global-vars/global-vars.service';
import { ModalService } from './services/modal/modal.service';
import { MediaQueryService } from './services/media-query/media-query.service';
import { AWSAuthService } from './services/aws/helpers/auth/aws-auth';
import { APP_ROUTES } from './app-routes';
import { DataPortsService } from './services/data/ports/ports.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(globalVarsService, modalService, mediaQueryService, AWSAuthService, router, dataPortsService) {
        this.globalVarsService = globalVarsService;
        this.modalService = modalService;
        this.mediaQueryService = mediaQueryService;
        this.AWSAuthService = AWSAuthService;
        this.router = router;
        this.dataPortsService = dataPortsService;
        this.mobileLayout = false;
        this.siderCollapsed = true;
        this.user = null;
        this.selectedPort = null;
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        //Get small screen value and subscribe to changes
        this.mobileLayout = this.mediaQueryService.getMobileLayout();
        this.mobileLayoutStream = this.mediaQueryService.mobileLayoutChange.subscribe(function (mobileLayout) {
            _this.mobileLayout = mobileLayout;
        });
        //Get sider collapsed value and subscribe to changes
        this.siderCollapsed = this.globalVarsService.getSiderCollapsed();
        this.siderCollapsedStream = this.globalVarsService.siderCollapsedStream.subscribe(function (siderCollapsed) {
            _this.siderCollapsed = siderCollapsed;
        });
        //Subscribe to router events
        this.routerEventsStream = this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                //Update current URL
                _this.globalVarsService.setCurrentURL(event.urlAfterRedirects.replace('/', ''));
            }
        });
        //Subscribe to events
        this.events = this.globalVarsService.eventStream
            .subscribe(function (event) {
            //Process event
            switch (event.name) {
                case EVENTS.LOAD_DATA:
                    _this.loadData();
                    break;
                case EVENTS.LOGOUT:
                    _this.confirmLogOut();
                    break;
                case EVENTS.SELECTED_PORT:
                    _this.setSelectedPort(event.data ? event.data : null);
                    break;
                case EVENTS.PORTS:
                    _this.setPorts(event.data ? event.data : null);
                    break;
            }
        });
        //Load data
        this.loadData();
    };
    AppComponent.prototype.ngOnDestroy = function () {
        //Unsubscribe from mobileLayout changes
        if (this.mobileLayoutStream) {
            this.mobileLayoutStream.unsubscribe();
        }
        //Unsubscribe from sider collapsed changes
        if (this.siderCollapsedStream) {
            this.siderCollapsedStream.unsubscribe();
        }
        //Unsubscribe from router events
        if (this.routerEventsStream) {
            this.routerEventsStream.unsubscribe();
        }
        //Unsubscribe from events
        if (this.events) {
            this.events.unsubscribe();
        }
    };
    AppComponent.prototype.loadData = function () {
        var _this = this;
        //User
        this.AWSAuthService.getCurrentEmployee()
            .then(function (employee) {
            //Set user
            _this.user = employee;
            _this.globalVarsService.setUser(employee);
            if (_this.user) {
                //Ports
                _this.dataPortsService.get()
                    .then(function (ports) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var selectedPort, lastPort;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.setPorts(ports);
                                if (!!this.selectedPort) return [3 /*break*/, 2];
                                selectedPort = null;
                                return [4 /*yield*/, this.dataPortsService.getLastPort()];
                            case 1:
                                lastPort = _a.sent();
                                if (lastPort) {
                                    selectedPort = lastPort;
                                }
                                else {
                                    selectedPort = ports[0];
                                }
                                this.setSelectedPort(selectedPort);
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); })
                    .catch(function (error) {
                    console.log(error);
                });
            }
        });
    };
    AppComponent.prototype.setSelectedPort = function (port) {
        this.selectedPort = port;
        this.globalVarsService.setSelectedPort(port);
    };
    AppComponent.prototype.setPorts = function (ports) {
        this.globalVarsService.setPorts(ports);
    };
    AppComponent.prototype.clearData = function () {
        this.globalVarsService.setUser(null);
        this.setSelectedPort(null);
    };
    AppComponent.prototype.confirmLogOut = function () {
        var _this = this;
        this.modalService.presentConfirmAlert({
            title: 'Confirm log out',
            content: 'Are you sure you want to log out?',
            okButtonText: 'Yes',
            okButtonFunction: function () { _this.performLogOut(); },
        });
    };
    AppComponent.prototype.performLogOut = function () {
        var _this = this;
        this.modalService.presentHardLoader({ content: 'Logging you out...' });
        this.AWSAuthService.signOut()
            .then(function () {
            _this.clearData();
            _this.modalService.dismissHardLoader();
            _this.router.navigate([APP_ROUTES.login]);
        })
            .catch(function () {
            _this.modalService.dismissHardLoader();
        });
    };
    return AppComponent;
}());
export { AppComponent };
