import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { AmplifyService } from 'aws-amplify-angular';
import { ModalService } from './../../../modal/modal.service';
import { AWSClass } from '../../aws-class';
export var AWS_AUTH = {
    SIGN_IN_OK: 0,
    SIGN_UP_CONFIRM_USER_CODE: -300,
    RESET_PASSWORD_CONFIRM_PASSWORD_CODE: -301,
    SIGN_IN_ERROR_UNKNOW: -1,
    SIGN_IN_ERROR_TOO_MANY_ATTEMPTS: -2,
    SIGN_IN_ERROR_USER_DISABLED: -3,
    SIGN_UP_ERROR_UNKNOW: -100,
    SIGN_UP_ERROR_CONFIRM_USER_CODE: -101,
    SIGN_UP_ERROR_INSECURE_PASSWORD: -102,
    RESET_PASSWORD_ERROR_CONFIRM_PASSWORD_CODE: -201,
    JWT_PASSWORD_REQUIRED_CODE: 'PasswordResetRequiredException',
    JWT_NOT_AUTHORIZED_CODE: 'NotAuthorizedException',
    JWT_USER_NOT_FOUND_CODE: 'UserNotFoundException',
    JWT_NETWORK_ERROR_CODE: 'NetworkError',
    JWT_NEW_PASSWORD_REQUIRED_CODE: 'NEW_PASSWORD_REQUIRED',
    JWT_NO_CURRENT_USER_ERROR_MESSAGE: 'No current user',
    JWT_USER_DISABLED_MESSAGE: 'User is disabled',
    COGNITO_ERROR_USER_NOT_CONFIRMED_CODE: 'UserNotConfirmedException',
};
;
var AWSAuthService = /** @class */ (function (_super) {
    tslib_1.__extends(AWSAuthService, _super);
    function AWSAuthService(amplifyService, modalService) {
        var _this = 
        //Call extending class constructor
        _super.call(this, modalService) || this;
        _this.amplifyService = amplifyService;
        _this.modalService = modalService;
        //Populate authObj
        _this.authObj = amplifyService.auth();
        return _this;
    }
    AWSAuthService.prototype.isValidSession = function () {
        //Check if there's a valid session
        return this.authObj.currentSession()
            .then(function (currentSession) {
            return Promise.resolve(currentSession ? true : false);
        })
            .catch(function () {
            return Promise.reject(false);
        });
    };
    AWSAuthService.prototype.signIn = function (username, password) {
        var _this = this;
        return this.authObj.signIn(username, password)
            .then(function (user) {
            if (user) {
                /*this.authObj.completeNewPassword(user, '!A1rbu5880', {})
                  .then((result) => {
                    console.log('result completeNewPassword', result);
                  })
                  .catch((error) => {
                    console.log('error completeNewPassword', error);
                  });*/
                /*this.authObj.updateUserAttributes(user, {
                  'given_name': 'Admin',
                  'family_name': 'Admin',
                })
                  .then((result) => {
                    console.log('result updateUserAttributes', result);
                  })
                  .catch((error) => {
                    console.log('error updateUserAttributes', error);
                  });*/
                //Check if user can log in. A challenge mighe be required first (for example force to change password)
                var signInUserSession = _.get(user, 'signInUserSession', null);
                if (!signInUserSession) {
                    return Promise.reject({ code: _.get(user, 'challengeName', null) });
                }
                else {
                    return Promise.resolve(true);
                }
            }
            else {
                return Promise.reject(false);
            }
        })
            .catch(function (error) {
            //Default error message
            var errorMessage = _.get(error, 'message', null);
            var errorContent = 'An unhandled error ocurred.' + (errorMessage ? (' Message: ' + errorMessage) : '');
            //Check if a code was sent and we can provide a better message
            var errorCode = _.get(error, 'code', null);
            switch (errorCode) {
                case AWS_AUTH.JWT_USER_NOT_FOUND_CODE:
                    errorContent = 'User not found. Please make sure you have logged in at least once in the mobile app first.';
                    break;
                case AWS_AUTH.JWT_NOT_AUTHORIZED_CODE:
                    errorContent = 'Combination of Username and Password is not valid.';
                    break;
                case AWS_AUTH.JWT_NEW_PASSWORD_REQUIRED_CODE:
                    errorContent = 'You must change your password before logging in.';
                    break;
                case AWS_AUTH.COGNITO_ERROR_USER_NOT_CONFIRMED_CODE:
                    errorContent = 'Your user hasn\'t been confirmed. Please login in the mobile app and follow the instructions.';
                    break;
            }
            return Promise.reject(_this.getErrorResponse({ message: errorContent, code: errorCode }, ''));
        });
    };
    AWSAuthService.prototype.signOut = function () {
        var _this = this;
        return this.authObj.signOut()
            .then(function () {
            return Promise.resolve(true);
        })
            .catch(function (error) {
            return Promise.reject(_this.getErrorResponse(error));
        });
    };
    AWSAuthService.prototype.getJWT = function () {
        return this.authObj.currentSession()
            .then(function (currentSession) {
            if (currentSession) {
                return Promise.resolve(currentSession.getIdToken().getJwtToken());
            }
            else {
                return Promise.reject('');
            }
        });
    };
    AWSAuthService.prototype.getCurrentEmployee = function () {
        var _this = this;
        return this.authObj.currentUserInfo()
            .then(function (user) {
            if (user) {
                var employee = {
                    firstName: _.get(user, 'attributes.given_name', '...'),
                    lastName: _.get(user, 'attributes.family_name', '...'),
                };
                return Promise.resolve(employee);
            }
            else {
                return Promise.resolve(null);
            }
        })
            .catch(function (error) {
            return Promise.reject(_this.getErrorResponse(error));
        });
    };
    return AWSAuthService;
}(AWSClass));
export { AWSAuthService };
