<ng-template #navigationMenu>
</ng-template>

<ng-template #locationMenu>
  <form nz-form id="menu-form" [formGroup]="menuForm" [ngClass]="{'full-width all-padding': isSideMenu, 'full': !isSideMenu}">
    <nz-select formControlName="portCode" nzShowSearch [nzPlaceHolder]="ports?.lenght > 0 ? 'Select Port' : 'Loading...'">
      <nz-option *ngFor="let port of ports" [nzLabel]="port.name" [nzValue]="port.code"></nz-option>
    </nz-select>
  </form>
</ng-template>

<ng-template #userMenu>
  <ul nz-menu id="layout-menu-user" nzTheme="dark" [ngClass]="{'full': !isSideMenu}" [nzMode]=" isSideMenu ? 'inline' : 'horizontal'">
    <li nz-submenu>
      <span title><i nz-icon type="user"></i>{{ user?.firstName }}</span>
      <ul>
        <li nz-menu-item (click)="performLogOut()"><i nz-icon type="logout"></i>Log out</li>
      </ul>
    </li>
  </ul>
</ng-template>

<ng-container *ngIf="!isSideMenu">
  <ng-container *ngTemplateOutlet="navigationMenu"></ng-container>
  <ng-container *ngTemplateOutlet="locationMenu"></ng-container>
  <ng-container *ngTemplateOutlet="userMenu"></ng-container>
</ng-container>

<ng-container *ngIf="isSideMenu">
  <ng-container *ngTemplateOutlet="locationMenu"></ng-container>
  <ng-container *ngTemplateOutlet="navigationMenu"></ng-container>
  <ng-container *ngTemplateOutlet="userMenu"></ng-container>
</ng-container>