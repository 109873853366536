import { OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { NzMessageService } from 'ng-zorro-antd';
import { debounceTime } from 'rxjs/operators';
import * as _ from 'lodash';
import { MediaQueryService } from 'src/app/services/media-query/media-query.service';
import { GlobalVarsService } from 'src/app/services/global-vars/global-vars.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { DataHomeContentService } from 'src/app/services/data/home-content/home-content.service';
export var QUILL_COLORS = [
    '#000000',
    '#666666',
    '#e0e0e0',
    '#ffffff',
    '#c63527',
    '#941100',
    '#692122',
    '#535f6a',
    '#001489',
    '#a0c3e3',
    '#0ba247',
    '#87f3b2',
    '#ffce00',
    '#fff1b8',
];
export var QUILL_MODULES = {
    toolbar: [
        //Font size
        [
            { 'header': [1, 2, 3, 4, false] }
        ],
        //Font format
        [
            'bold',
            'italic',
            'underline',
        ],
        //Link
        ['link'],
        //Colors
        [
            { 'color': QUILL_COLORS },
            { 'background': QUILL_COLORS }
        ],
        //Clean format
        ['clean'],
    ]
};
export var QUILL_FORMATS = [
    'header',
    'bold',
    'italic',
    'underline',
    'link',
    'color',
    'background'
];
export var PREVIEW_DEVICE_TABLET = 'tablet';
export var PREVIEW_DEVICE_MOBILE = 'mobile';
export var PREVIEW_ORIENTATION_LANDSCAPE = 'landscape';
export var PREVIEW_ORIENTATION_PORTRAIT = 'portrait';
export var FORM_CHANGE_DELAY = 125;
var HomeComponent = /** @class */ (function () {
    function HomeComponent(formBuilder, sanitizer, mediaQueryService, globalVarsService, dataHomeContentService, modalService, renderer, nzMessageService) {
        this.formBuilder = formBuilder;
        this.sanitizer = sanitizer;
        this.mediaQueryService = mediaQueryService;
        this.globalVarsService = globalVarsService;
        this.dataHomeContentService = dataHomeContentService;
        this.modalService = modalService;
        this.renderer = renderer;
        this.nzMessageService = nzMessageService;
        this.primaryPreview = null;
        this.secondaryPreview = null;
        this.mobileLayout = true;
        this.previewDevices = [
            { id: PREVIEW_DEVICE_MOBILE, name: 'Mobile' },
            { id: PREVIEW_DEVICE_TABLET, name: 'Tablet' },
        ];
        this.previewOrientations = [
            { id: PREVIEW_ORIENTATION_PORTRAIT, name: 'Portrait' },
            { id: PREVIEW_ORIENTATION_LANDSCAPE, name: 'Landscape' },
        ];
        this.singleColumnPreview = true;
        this.previewContainerClasses = {};
        this.loading = true;
        this.port = null;
        this.homeContent = {};
        this.contacts = [];
        this.editContactId = null;
        this.unsavedData = false;
    }
    HomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        //Set modules
        this.primaryQuillModules = JSON.parse(JSON.stringify(QUILL_MODULES));
        this.secondaryQuillModules = JSON.parse(JSON.stringify(QUILL_MODULES));
        this.secondaryQuillModules.toolbar[0] = [{ 'header': [2, 3, 4, false] }];
        //Set formats
        this.primaryQuillFormats = QUILL_FORMATS;
        this.secondaryQuillFormats = QUILL_FORMATS;
        //Set form
        this.setForm();
        this.setContactForm();
        //Get mobileLayout value and subscribe to changes
        this.mobileLayout = this.mediaQueryService.getMobileLayout();
        this.mobileLayoutSubscription = this.mediaQueryService.mobileLayoutChange
            .subscribe(function (mobileLayout) {
            _this.mobileLayout = mobileLayout;
        });
        //Get port value and subscribe to changes
        this.port = this.globalVarsService.getSelectedPort();
        this.portSubscription = this.globalVarsService.selectedPortStream
            .subscribe(function (port) {
            if (port) {
                _this.port = port;
                _this.getHomeContent();
            }
        });
        //Get unsaved cahnges value and subscribe to changes
        this.unsavedData = this.globalVarsService.getUnsavedData();
        this.unsavedDataSubscription = this.globalVarsService.unsavedDataStream
            .subscribe(function (unsavedData) {
            _this.unsavedData = unsavedData;
        });
    };
    HomeComponent.prototype.ngOnDestroy = function () {
        //Unsubscribe from changes on the primary content
        if (this.primaryChangesSubscription) {
            this.primaryChangesSubscription.unsubscribe();
        }
        //Unsubscribe from changes on the secondary content
        if (this.secondaryChangesSubscription) {
            this.secondaryChangesSubscription.unsubscribe();
        }
        //Unsubscribe from changes on device preview
        if (this.deviceChangesSubscription) {
            this.deviceChangesSubscription.unsubscribe();
        }
        //Unsubscribe from changes on orientation preview
        if (this.orientationChangesSubscription) {
            this.orientationChangesSubscription.unsubscribe();
        }
        //Unsubscribe from mobile layout changes
        if (this.mobileLayoutSubscription) {
            this.mobileLayoutSubscription.unsubscribe();
        }
        //Unsubscribe from port changes
        if (this.portSubscription) {
            this.portSubscription.unsubscribe();
        }
        //Unsubscribe from unsaved data changes
        if (this.unsavedDataSubscription) {
            this.unsavedDataSubscription.unsubscribe();
        }
    };
    HomeComponent.prototype.setForm = function () {
        var _this = this;
        this.homeForm = this.formBuilder.group({
            primary: [null],
            secondary: [null],
            previewDevice: [this.previewDevices[0].id],
            previewOrientation: [this.previewOrientations[0].id],
        });
        this.setPreviewClasses();
        //Subscribe to changes on the primary content
        this.primaryChangesSubscription = this.homeForm.get('primary').valueChanges
            .pipe(debounceTime(FORM_CHANGE_DELAY))
            .subscribe(function (value) {
            _this.primaryPreview = _this.getSafeHtmlValue(value);
            _this.setChangesNotSaved(true);
        });
        //Subscribe to changes on the secondary content
        this.secondaryChangesSubscription = this.homeForm.get('secondary').valueChanges
            .pipe(debounceTime(FORM_CHANGE_DELAY))
            .subscribe(function (value) {
            _this.secondaryPreview = _this.getSafeHtmlValue(value);
            _this.setChangesNotSaved(true);
        });
        //Subscribe to changes on device preview
        this.deviceChangesSubscription = this.homeForm.get('previewDevice').valueChanges
            .subscribe(function (value) {
            //Set single column preview
            _this.singleColumnPreview = value === PREVIEW_DEVICE_MOBILE && _this.homeForm.get('previewOrientation').value === PREVIEW_ORIENTATION_PORTRAIT;
            //Set classes
            _this.setPreviewClasses();
        });
        //Subscribe to changes on orientation preview
        this.orientationChangesSubscription = this.homeForm.get('previewOrientation').valueChanges
            .subscribe(function (value) {
            //Set single column preview
            _this.singleColumnPreview = value === PREVIEW_ORIENTATION_PORTRAIT && _this.homeForm.get('previewDevice').value === PREVIEW_DEVICE_MOBILE;
            //Set classes
            _this.setPreviewClasses();
        });
    };
    HomeComponent.prototype.getSafeHtmlValue = function (value) {
        var safeHtmlValue = null;
        if (value && value !== '') {
            safeHtmlValue = this.sanitizer.bypassSecurityTrustHtml(value);
        }
        return safeHtmlValue;
    };
    HomeComponent.prototype.setPreviewClasses = function () {
        this.previewContainerClasses = {
            'preview-container-tablet': this.homeForm.controls.previewDevice.value === PREVIEW_DEVICE_TABLET,
            'preview-container-tablet-portrait': this.homeForm.controls.previewDevice.value === PREVIEW_DEVICE_TABLET && this.homeForm.controls.previewOrientation.value === PREVIEW_ORIENTATION_PORTRAIT,
            'preview-container-tablet-landscape': this.homeForm.controls.previewDevice.value === PREVIEW_DEVICE_TABLET && this.homeForm.controls.previewOrientation.value === PREVIEW_ORIENTATION_LANDSCAPE,
            'preview-container-mobile': this.homeForm.controls.previewDevice.value === PREVIEW_DEVICE_MOBILE,
            'preview-container-mobile-portrait': this.homeForm.controls.previewDevice.value === PREVIEW_DEVICE_MOBILE && this.homeForm.controls.previewOrientation.value === PREVIEW_ORIENTATION_PORTRAIT,
            'preview-container-mobile-landscape': this.homeForm.controls.previewDevice.value === PREVIEW_DEVICE_MOBILE && this.homeForm.controls.previewOrientation.value === PREVIEW_ORIENTATION_LANDSCAPE,
        };
    };
    HomeComponent.prototype.setPreviewValue = function (value, isPrimary) {
        if (isPrimary) {
            //Primary
            this.primaryPreview = this.getSafeHtmlValue(value);
            this.homeForm.controls.primary.setValue(value, { emitEvent: false });
        }
        else {
            //Secondary
            this.secondaryPreview = this.getSafeHtmlValue(value);
            this.homeForm.controls.secondary.setValue(value, { emitEvent: false });
        }
    };
    HomeComponent.prototype.clearPreviewValue = function (isPrimary) {
        if (isPrimary) {
            this.setPreviewValue(null, true);
        }
        else {
            this.setPreviewValue(null);
        }
        this.setChangesNotSaved(true);
    };
    HomeComponent.prototype.getHomeContent = function () {
        var _this = this;
        if (this.port) {
            this.setPreviewValue(null, true);
            this.setPreviewValue(null);
            this.contacts = [];
            this.loading = true;
            this.setChangesNotSaved(false);
            this.dataHomeContentService.get()
                .then(function (homeContent) {
                //Set home content
                _this.homeContent = homeContent;
                //Get port content
                var portContent = _this.homeContent[_this.port.code];
                if (portContent) {
                    //Set individual contents
                    _this.setPreviewValue(portContent.primary[0].content['en-AU'], true);
                    _this.setPreviewValue(portContent.secondary[0].content['en-AU']);
                    //Set contacts
                    _this.contacts = portContent.contacts ? portContent.contacts : [];
                }
                _this.loading = false;
            })
                .catch(function (error) {
                console.log('error', error);
                _this.loading = false;
            });
        }
    };
    HomeComponent.prototype.saveHomeContent = function () {
        var _this = this;
        this.setChangesNotSaved(false);
        //Check if object has been initialized for the current port
        if (!this.homeContent[this.port.code]) {
            this.homeContent[this.port.code] = {
                primary: [{ 'id': '1', 'content': { 'en-AU': '' } }],
                secondary: [{ 'id': '2', 'content': { 'en-AU': '' } }],
                contacts: [],
            };
        }
        //Set individual contents
        this.homeContent[this.port.code].primary[0].content['en-AU'] = this.homeForm.controls.primary.value;
        this.homeContent[this.port.code].secondary[0].content['en-AU'] = this.homeForm.controls.secondary.value;
        //Set contacts
        this.homeContent[this.port.code].contacts = this.contacts;
        this.loading = true;
        return this.dataHomeContentService.set(this.homeContent)
            .then(function () {
            _this.loading = false;
        })
            .catch(function () {
            _this.loading = false;
            _this.setChangesNotSaved(true);
        });
    };
    HomeComponent.prototype.confirmCancel = function () {
        var _this = this;
        this.modalService.presentConfirmAlert({
            title: 'Confirm cancel',
            content: 'Are you sure you want cancel the current changes?',
            okButtonText: 'Yes',
            okButtonFunction: function () {
                _this.getHomeContent();
                _this.setChangesNotSaved(false);
            },
        });
    };
    HomeComponent.prototype.setContactForm = function (contact) {
        this.contactForm = this.formBuilder.group({
            name: [contact ? contact.name : null, [Validators.required]],
            jobTitle: [contact ? contact.jobTitle : null],
            phone: [contact ? contact.phone : null],
            email: [contact ? contact.email : null],
            empId: [contact ? contact.empId : null],
        });
    };
    HomeComponent.prototype.addContact = function () {
        var _this = this;
        var id = 0;
        this.contacts = this.contacts.concat([
            {
                id: id,
                name: '',
                jobTitle: '',
                phone: '',
                email: '',
                empId: null,
            }
        ]);
        setTimeout(function () { _this.editContact(id); }, 0);
    };
    HomeComponent.prototype.getNextId = function () {
        var maxId = 0;
        var contact = _.maxBy(this.contacts, function (c) { return c.id; });
        if (contact) {
            maxId = contact.id + 1;
        }
        else {
            maxId++;
        }
        return maxId;
    };
    HomeComponent.prototype.editContact = function (id, event) {
        /*if (event) {
          event.preventDefault();
          event.stopPropagation();
        }*/
        var _this = this;
        this.editContactId = id;
        //Populate form
        var contact = _.find(this.contacts, { id: id });
        if (contact) {
            this.setContactForm(contact);
        }
        setTimeout(function () { _this.renderer.selectRootElement('#inputContactName').focus(); }, 0);
        this.setChangesNotSaved(true);
    };
    HomeComponent.prototype.deleteContact = function (id) {
        this.contacts = _.remove(this.contacts, function (c) { return c.id !== id; });
        this.setChangesNotSaved(true);
    };
    HomeComponent.prototype.saveContact = function (id) {
        var contact = _.find(this.contacts, { id: id });
        if (contact) {
            contact.name = this.contactForm.get('name').value;
            contact.jobTitle = this.contactForm.get('jobTitle').value;
            contact.phone = this.contactForm.get('phone').value;
            contact.email = this.contactForm.get('email').value;
            contact.empId = this.contactForm.get('empId').value;
            //Check if it's new contact
            if (contact.id === 0) {
                contact.id = this.getNextId();
            }
        }
        this.editContactId = null;
    };
    HomeComponent.prototype.cancelContact = function (id) {
        this.editContactId = null;
        this.setContactForm();
        //Check if it's new contact
        if (id === 0) {
            this.deleteContact(id);
        }
    };
    HomeComponent.prototype.setChangesNotSaved = function (value) {
        if (value) {
            if (!this.unsavedData) {
                var options = {
                    nzDuration: 0
                };
                this.nzMessageService.info('Changes are not saved yet.', options);
                this.unsavedData = true;
                this.globalVarsService.setUnsavedData(this.unsavedData);
            }
        }
        else {
            this.nzMessageService.remove();
            this.unsavedData = false;
            this.globalVarsService.setUnsavedData(this.unsavedData);
        }
    };
    HomeComponent.prototype.dropContact = function (event) {
        moveItemInArray(this.contacts, event.previousIndex, event.currentIndex);
        this.contacts = this.contacts.slice();
        this.setChangesNotSaved(true);
    };
    return HomeComponent;
}());
export { HomeComponent };
