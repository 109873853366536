import { LocalStorage } from '@ngx-pwa/local-storage';
import * as _ from 'lodash';
import { AWSDataPortsService } from '../../aws/data/ports/aws-data-ports';
import { AWS_CLASS } from '../../aws/aws-class';
import * as i0 from "@angular/core";
import * as i1 from "../../aws/data/ports/aws-data-ports";
import * as i2 from "@ngx-pwa/local-storage";
var DataPortsService = /** @class */ (function () {
    function DataPortsService(AWSDataPortsService, localStorage) {
        this.AWSDataPortsService = AWSDataPortsService;
        this.localStorage = localStorage;
        this.DB_LAST_PORT_KEY = 'last-port';
    }
    DataPortsService.prototype.get = function () {
        var _this = this;
        return this.AWSDataPortsService.get()
            .then(function (response) {
            var ports = [];
            if (response.status === AWS_CLASS.RESPONSES.STATUSES.SUCCESS) {
                var responsePorts = response.body;
                if (Array.isArray(responsePorts) && responsePorts.length > 0) {
                    for (var _i = 0, responsePorts_1 = responsePorts; _i < responsePorts_1.length; _i++) {
                        var responsePort = responsePorts_1[_i];
                        ports.push(_this.mapAPIItem(responsePort));
                    }
                }
            }
            return Promise.resolve(ports);
        })
            .catch(function (error) {
            return Promise.reject(error);
        });
    };
    DataPortsService.prototype.mapAPIItem = function (apiItem) {
        var dataItem = {
            code: _.get(apiItem, 'Code', ''),
            name: _.get(apiItem, 'Name', ''),
            country: _.get(apiItem, 'Country', ''),
            active: (_.get(apiItem, 'Active', false) === '1' ? true : false),
        };
        return dataItem;
    };
    DataPortsService.prototype.getLastPort = function () {
        return this.localStorage.getItem(this.DB_LAST_PORT_KEY).toPromise();
    };
    DataPortsService.prototype.setLastPort = function (port) {
        return this.localStorage.setItem(this.DB_LAST_PORT_KEY, port).toPromise();
    };
    DataPortsService.prototype.deleteLastPort = function () {
        return this.localStorage.removeItem(this.DB_LAST_PORT_KEY).toPromise();
    };
    DataPortsService.ngInjectableDef = i0.defineInjectable({ factory: function DataPortsService_Factory() { return new DataPortsService(i0.inject(i1.AWSDataPortsService), i0.inject(i2.LocalStorage)); }, token: DataPortsService, providedIn: "root" });
    return DataPortsService;
}());
export { DataPortsService };
